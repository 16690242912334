@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/fonts.scss";
@import "../../../../assets/scss/function.scss";

.SearchNavbar {
  align-items: center;
  background: $white;
  border: 2px solid $dim_gray;
  border-radius: 30px;
  color: $dim_gray;
  cursor: pointer;
  display: flex;
  font-family: 'Grotesk', sans-serif;
  font-size: pxToRem(15px);
  height: 40px;
  justify-content: center;
  margin: 0;
  outline: none;
  padding: 0 12px;
  width: fit-content;

  &:hover {
    background-color: rgba(176, 224, 98, 0.575);
    opacity: 1;
  }

  &.active {
    background: rgba(176, 224, 98, 0.575);
  }
}
