@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/function";

.SearchTagComment {
  background-color: $white;
  border-bottom: 1px solid #FAFAFA;
  border-radius: 0 10px 10px 0;
  border-top: 1px solid #FAFAFA;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: $primary_font_book;
  height: 45px;
  justify-content: center;
  padding: 10px;
  width: 100%;

  &:hover {
    background-color: #e6e6f8;
  }

  p {
    font-size: pxToRem(18px);
    font-weight: 400;
    margin: 0;
    padding: 0 0 0 17px;
  }
}
