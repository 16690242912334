@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.Home {
  width: 100%;

  .learn-too {
    flex-direction: column;

    p {
      margin-bottom: 39px;
      margin-left: 25px;
    }

    .ContentCardMobile {
      p {
        margin: 0;
      }
    }

    &.isLogged {
      padding-bottom: 100px;
    }
  }

  .high-container {
    .BluePopUp {
      p {
        color: white;
      }
    }    
    
    p {
      color: $blue_magenta;
      font-family: $secundary_font;
      font-size: pxToRem(30px);
      margin-bottom: 30px;
      margin-left: 6%;
    }

    &.logged {
      margin-top: 150px;

      &:last-child {
        margin-top: 50px;
      }
    }
  }
}
