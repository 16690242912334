@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/function";
@import "../../../../assets/scss/fonts";

.chat-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 80px;
  z-index: 20;
  height: 80px;
  background: #001f3f;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  padding: 0 10px;
  transition: background 0.3s;
  background-color: #0505be;
  border: 3px solid #0505be;
  box-sizing: border-box;

  &.expanded {
    height: 69px;
    justify-content: space-between;
    align-items: center;
    width: 380px;
    padding: 10px 30px 10px 20px;

    .container-infos {
      display: flex;
      align-items: center;
      gap: 20px;

      .info-text {
        p {
          font-size: 24px;
          font-weight: 700;
          font-family: $secundary_font;
          color: white;
          margin: 0;
        }

        span {
          font-size: 16px;
          font-family: $secundary_font;
          font-weight: 500;
          color: white;
        }
      }
    }

    .image-container {
      display: flex;
      align-items: center;
      background-color: white;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      justify-content: center;
      align-items: center;

      img {
        width: 70%;
      }
    }
  }

  &.blink {
    border-color: #6cb5eb;
  }

  img {
    border-radius: 50%;
    width: 90%;
  }

  .orange-dot {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    background-color: #ff851b;
    border-radius: 50%;
  }

  .arrow {
    font-size: 20px;
  }
}
