@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/fonts.scss";
@import "../../../../assets/scss/function.scss";
@import "../../../../assets/scss/mixins.scss";

.TagList {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 524px;
  justify-content: space-between;
  opacity: 1;
  overflow: hidden;
  transition-delay: 5s;
  width: 100%;
  z-index: 1;

  .title-search {
    color: $black;
    font-family: $secundary_font;
    font-size: pxToRem(25px);
    letter-spacing: -0.4px;
    text-align: center;
    width: 687px;
  }

  &.active {
    .Button {
      background-color: $light_green;
      border-radius: 3px;
      color: $black;
      display: inline-block;
      font-family: $primary_font_book;
      font-weight: 700;
      opacity: 1;
    }

    .tags-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      left: 0;
      opacity: 1;
      transition: left 2s ease 0s;
      width: 100%;
    }

    .title-search {
      text-align: center;
    }
  }

  .Button {
    display: none;
    opacity: 0;
  }

  .tags-container {
    height: 359px;
    position: relative;
    transition: all 50s ease-in-out;
    width: 1800px;

    .tag {
      align-items: center;
      display: flex;
      gap: 16px;
      justify-content: center;
      margin-top: 21px;
      width: 1590px;

      &:nth-child(2) {
        margin: 0;
      }

      .Tag {
        opacity: 0.88;
      }
    }
  }

  p.tag-error {
    color: $white;
    margin: 10px 0;
  }


  @include breakpoint(desktop-small) {

    .title-search {
      text-align: left;
    }

    .tags-container {
      left: 150%;
    }
  }

  @include breakpoint(desktop-medium) {

    .title-search {
      text-align: center;
    }

    .tags-container {
      left: 100%;
    }
  }

  @include breakpoint(desktop-large) {

    .tags-container {
      left: 70%;
    }
  }
}

.exit-tags {
  align-items: center;
  background-color: $white;
  border: none;
  color: $black;
  cursor: pointer;
  display: flex;
  font-size: pxToRem(30px);
  height: 50px;
  left: 3%;
  outline: none;
  position: relative;
  top: -23%;
  width: 50px;

  span {
    height: 40px;
    transform: rotate(180deg);
  }

  p {
    font-family: $primary_font_book;
    font-size: pxToRem(25px);
    font-weight: 700;
    height: 35px;
    margin-left: 5px;
    opacity: 0;
  }

  &:hover {
    p {
      opacity: 0.6;
      transition: 0.5s;
    }
  }
}
