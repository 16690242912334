@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/fonts.scss";

.MediaFormat {
  height: 15%;

  .checkboxContent {
    align-items: center;
    height: 75px;
    justify-content: space-between;
    width: 50%;
    display: flex;

    .container {
      -moz-user-select: none;
      -ms-user-select: none;
      -webkit-user-select: none;
      align-items: center;
      cursor: pointer;
      display: block;
      font-family: $primary_font_book;
      font-size: pxToRem(16px);
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 2px;
      position: relative;
      user-select: none;
    }

    .container input {
      cursor: pointer;
      opacity: 0;
      position: absolute;
    }

    .checkmark {
      background-color: white;
      border-radius: 50%;
      border: 3px solid $strong_blue;
      height: 20px;
      left: 0;
      position: absolute;
      top: 0;
      width: 20px;
    }

    .checkmark:after {
      content: "";
      display: none;
      position: absolute;
    }

    .container input:checked ~ .checkmark:after {
      display: block;
    }

    .container .checkmark:after {
      background: $strong_blue;
      border-radius: 10px;
      height: 73%;
      left: 2px;
      margin-right: 2px;
      top: 3px;
      width: 80%;
    }
  }
}
