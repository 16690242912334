@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.ContentTagList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  left: -5px;
  margin-bottom: 109px;
  margin-top: 14px;
  max-width: 886px;
  padding-left: 32px;
  position: relative;
  top: 1px;

  .Tag {
    align-items: center;
    background-color: $light_green;
    border: none;
    color: black;
    cursor: pointer;
    display: flex;
    font-size: pxToRem(11px);
    font-weight: 500;
    height: 22px;
    min-width: 150px;
    padding: 0 12px;
    width: fit-content;
  }

  @include breakpoint(desktop-small) {
    margin-top: 28px;
    padding-left: 2px;

    .Tag {
      font-size: pxToRem(16px);
      height: 28px;
      min-width: 131px;
    }
  }
}
