@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.SuggestionTagList {
  align-items: center;
  background: $white;
  color: black;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  height: 700px;
  justify-content: center;

  h2 {
    font-size: pxToRem(36px);
    font-weight: 600;
  }

  p {
    font-size: pxToRem(18px);
    font-weight: 400;
    height: 82px;
    line-height: 25px;
    margin: 16px 0 11px 0;
    text-align: center;
    width: 500px;
  }

  p.tag-error {
    color: $pink_button_mobile;
    height: auto;
    margin-bottom: -30px;
    margin-top: 30px;
    width: auto;
  }

  .suggestion-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 232px;
    justify-content: center;
    width: 1141px;

    .suggestion-tags {
      display: flex;
      gap: 0 18px;
      margin-top: 20px;

      &:nth-child(1){
        margin-left: 94px;
      }

      &:nth-child(2){
        margin-right: 94px;
      }

      &:nth-child(3){
        margin-left: 94px;
      }

      &:nth-child(4){
        margin-right: 94px;
      }

      .Tag {
        border: 2px solid $black;
        color: $black;
        height: 40px;
        min-width: 175px;
        width: fit-content;

        &:hover {
          background-color: #5a6666cc;
          border: solid #5a6666cc;
          color: white;
        }

        &.active {
          color: white;
          background-color: $black;
        }
      }
    }
  }

  .Button {
    border-radius: 0;
    color: black;
    font-weight: 700;
    margin-top: 54px;
  }
}
