@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.BottomComponent {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  h2 {
    color: $strong_blue;
    font-family: 'Grotesk';
    font-size: pxToRem(38px);
    font-weight: 700;
    letter-spacing: -0.4px;
  }

  p {
    font-size: pxToRem(23px);
  }

  .firstSize {
    width: 85%;
  }

  .secondSize {
    width: 95%;
  }

  .firstContainer, .secondContainer {
    align-items: center;
    display: flex;
    height: 750px;
    justify-content: space-between;
    width: 80%;

    .imageBox {
      display: flex;
      justify-content: center;
      width: 40%;
    }

    .textBox {
      display: flex;
      flex-direction: column;
      height: 40%;
      justify-content: space-around;
      width: 50%;
    }
  }

  .thirdContainer {
    align-items: center;
    display: flex;
    height: 750px;
    justify-content: center;
    width: 80%;

    .textBox {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 40%;
      justify-content: space-between;

      p {
        padding-top: 50px;
      }
    }

    .Button {
      border-radius: 0px;
      color: $black;
    }
  }
}
