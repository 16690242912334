@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";

.Checkbox {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-family: Grotesk, sans-serif;
  font-size: pxToRem(18px);
  font-weight: 400;
  padding-left: 43px;
  position: relative;
  user-select: none;

  input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    outline: none;
    position: absolute;
    width: 0;

    &:checked {
      ~ .checkmark {
        background-color: #0505BE;

        &:after {
          display: block;
        }
      }
    }
  }

  .checkmark {
    background-color: $white;
    border: 2px solid #0505BE;
    border-radius: 3px;
    height: 22px;
    left: 0;
    position: absolute;
    width: 22px;

    &:after {
      border: solid white;
      border-width: 0 2px 2px 0;
      color: white;
      content: "";
      display: none;
      height: 12px;
      left: 7px;
      position: absolute;
      top: 2px;
      transform: rotate(45deg);
      width: 6px;
    }
  }

  &:hover{
    input {
      ~ .checkmark {
        background-color: #0505BE;
      }
    }
  }
}
