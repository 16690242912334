@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/function";

.HistoryDay {
  padding-bottom: 200px;

  p {
    color: $black_dark;
    font-size: pxToRem(32px);
    font-weight: 600;
  }

  .history-day-container {
    display: flex;
    flex-wrap: wrap;
    gap: 19px;
    height: 100%;
    justify-content: flex-start;
    min-height: 40vh;
    padding: 34px 0 0 0;
  }
}
