$background_color_modal_mobile: #00000099;
$black: #2E2E2E;
$black_border_opacity: #2929290F;
$black_dark: #212121;
$blue_dark_button: #0E7CAF;
$blue_magenta: #1E1EC4;
$dim_gray: #737373;
$erie_black: #222222;
$gray_input: #BDBDBD;
$gray_register: #929292;
$light_black: #212121;
$light_green: #CFEC59;
$pink_button_mobile: #F41157;
$strong_blue: #0505BE;
$white: #FFFFFF;
$white_background_tag: #FFFFFF66;
$white_smoke: #F2F2F2;

$reading_size: 15px;
$search_tag: 22px;
$subtitle_size: 24px;
$tag_size: 26px;
$small_mobile_text: 17px;
$title_size: 36px;

$primary_font_book: "Grotesk", sans-serif;
$secundary_font: "SpaceGrotesk", sans-serif;

$breakpoints: (
 "mobile-medium": 400px,
 "mobile-larger": 576px,
 "tablet": 768px,
 "tablet-large": 992px,
 "desktop-small": 1024px,
 "desktop-medium": 1440px,
 "desktop-large": 1600px,
 "desktop-extra-large": 1800px
);
