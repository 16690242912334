@import "../../assets/scss/variables";
@import "../../assets/scss/function";
@import "../../assets/scss/mixins";
@import "../../assets/scss/fonts";

.FollowTags {
  scroll-behavior: smooth;
  
  .follow-tags-home {
    align-items: center;
    background-image: url("../../assets/images/bg-login.png");
    background-position: right 70% bottom 45%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    text-align: center;

    h3 {
      color: $white;
      font-size: pxToRem(34px);
      font-weight: 700;
      margin-top: 188px;
    }

    p {
      color: $white;
      font-family: Grotesk, sans-serif;
      font-weight: 400;
      margin-top: 48px;
      opacity: 60%;
      text-indent: -10px;
      width: 299px;

      &:nth-of-type(2) {
        margin-top: 25px;
        width: 250px;
      }
    }

    .Button {
      align-items: center;
      background-color: $light_green;
      border-radius: 33px;
      color: $black_dark;
      display: flex;
      font-size: pxToRem($small_mobile_text);
      font-weight: 500;
      height: 37px;
      justify-content: center;
      margin-top: 50px;
      width: 182px;
    }
  }

  .follow-tags-container {

    .interests-tags {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h3 {
        font-size: pxToRem(18px);
        font-weight: 700;
        margin: 68px 0 0 0;
      }

      p {
        color: $dim_gray;
        font-size: pxToRem($reading_size);
        font-weight: 400;
        margin-top: 27px;
        text-align: center;
        width: 300px;
      }
    }

    .scroll-tags {
      display: flex;
      flex-wrap: wrap;
      margin-left: 25px;
      max-height: 400px;
      overflow: scroll;
      padding: 20px 0 20px 0;

      .TagFollow {
        margin: 8px;
      }
    }

    .continue-tags {
      align-items: center;
      border-top: 1px solid $white_smoke;
      display: flex;
      justify-content: center;
      width: 100%;

      .Button {
        align-items: center;
        background-color: $white;
        border: 2px solid $black_dark;
        border-radius: 33px;
        color: $black_dark;
        display: flex;
        font-size: pxToRem($small_mobile_text);
        font-weight: 500;
        height: 37px;
        justify-content: center;
        margin: 50px 0;
        pointer-events: none;
        transition: background-color 0.8s ease;
        width: 182px;
      }

      .ButtonActive {
        align-items: center;
        background-color: $light_green;
        border: 2px solid $light_green;
        border-radius: 33px;
        color: $black_dark;
        display: flex;
        font-family: Grotesk, sans-serif;
        font-size: pxToRem($small_mobile_text);
        font-weight: 500;
        height: 37px;
        justify-content: center;
        margin: 50px 0;
        outline: none;
        transition: background-color 0.8s ease;
        width: 182px;
      }
    }
  }
}
