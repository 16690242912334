@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/fonts";

.PlaylistAdd {
  background-color: $white;
  border-radius: 10pt;
  height: 100vh;
  position: fixed;
  top: 24px;
  transition: visibility 0s, opacity 0.5s linear;
  width: 100%;
  z-index: 15;

  .exit-entry-mobile {
    background: transparent;
    border: none;
    height: 30px;
    margin: 0;
    position: absolute;
    right: 22px;
    top: 13px;
    width: 30px;
    z-index: 11;

    &:before, &:after {
      background-color: $black_dark;
      content: ' ';
      height: 20px;
      left: 15px;
      position: absolute;
      width: 1px;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    @include breakpoint(desktop-small) {
      display: none;
    }
  }

  .playlist-add-top {
    height: 80%;
    padding: 27px 0 20px 0;

    p {
      color: $black_dark;
      font-size: pxToRem($small_mobile_text);
      font-size: pxToRem(16px);
      font-weight: 600;
      padding-left: 28px;
    }

    .playlist-add-container {
      display: flex;
      justify-content: center;
      margin-top: 53px;

      .Button {
        border-radius: 28px;
        color: $black_dark;
        font-size: pxToRem(15px);
        font-weight: 500;
        height: 56px;
        width: 305px;
      }
    }

    .playlist-add-list {
      height: 85%;
      margin-top: 44px;
      overflow-y: scroll;
      padding: 0 0 100px 28px;

      button {
        background-color: $white;
        background-image: url("../../../assets/images/add-playlist-content.svg");
        background-position: right;
        background-repeat: no-repeat;
        border: none;
        font-family: Grotesk, sans-serif;
        font-size: pxToRem(15px);
        font-weight: 400;
        margin-bottom: 38px;
        min-height: 29px;
        outline: none;
        text-align: left;
        width: 90%;
      }
    }
  }
}
