@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.LibraryInfo {
  background-color: $white;
  border-bottom: 1px solid #DCDCDC;
  display: flex;
  height: 59px;
  justify-content: flex-end;
  margin-right: 25px;
  padding-top: 70px;
  position: fixed;
  width: 100%;

  div {
    display: flex;
    overflow-y: hidden;
    position: relative;
    width: 100%;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;

    button {
      background-color: $white;
      border: none;
      color: $gray_register;
      font-family: Grotesk, sans-serif;
      font-size: pxToRem(16px);
      margin: 0 10px;
      min-height: 100%;
      min-width: 36%;
      outline: none;
      width: 178px;

      &.active {
        border-bottom: 1px solid $light_black;
        color: $light_black;
      }
    }
  }
}
