@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/fonts.scss";
@import "../../../assets/scss/function.scss";

.PresentationHome {
  background-color: #1212c1;
  background-image: url(../../../assets/images/home-background.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: 74% 784px;
  height: 706px;
  padding-top: 75px;
  width: 100%;


  .container-presentation {
    align-items: center;
    display: flex;
    height: 100%;

    .text-presentation {
      margin-left: 7%;
      width: 50%;
    }

    h1 {
      color: $white;
      font-family: $primary_font_book;
      font-size: pxToRem(50px);
      margin-bottom: 37px;
    }

    p {
      color: $white;
      font-family: $secundary_font;
      font-size: pxToRem(25px);
      font-weight: 400;
      line-height: 35px;
      max-width: 720px;
    }
  }

  .matrix {
    height: 33px;
    left: 47%;
    position: relative;
    top: 0%;
    width: 144px;
  }
}
