@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.ContentRelated {
  margin-bottom: 80px;

  .title-related {
    margin-bottom: 58px;
    padding-left: 25px;
  }

  .ContentCardMobile {
    margin-top: 18px;

    &:first-child {
      margin: 0;
    }
  }
}
