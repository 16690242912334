@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/function";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/fonts.scss";

.LoginSuccessMessage {
  align-items: center;
  background-color: $white;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 1;

  .messageContent {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 180px;
      justify-content: space-between;
      padding-right: 1%;

    p {
      color: $strong_blue;
      font-family: 'Grotesk', sans-serif;
      font-size: pxToRem(18px);
      font-weight: 600;
      width: 305px;
    }
  }

  @include breakpoint(desktop-small){
    align-items: center;
    background-color: $white;
    display: flex;
    height: 100%;
    justify-content: center;
    position: fixed;
    width: 41%;
    z-index: 1;
  }
}
