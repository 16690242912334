@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/fonts";

.CreateContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 170px 0 0 0;
  width: 100%;

  .container-create {
    display: flex;
    width: 100%;

    .left-create {
      margin-left: 128px;
      width: 45%;

      h2 {
        font-family: $secundary_font;
        font-size: pxToRem(30px);
      }

      .Input {
        margin-top: 40px;

        label {
          font-family: $secundary_font;
          font-size: pxToRem(20px);
          font-weight: 700;
          margin-bottom: 5px;
        }

        input {
          border: 3px solid black;
          font-family: $secundary_font;
          width: 98%;

          &:focus {
            border: 3px solid $blue_magenta;
          }

          &::placeholder {
            opacity: 0.5;
          }
        }
      }

      .checkboxContent {
        display: flex;
        height: 60px;
        width: 100%;
      }

      .header-sendmedia {
        display: flex;
        width: 100%;

        .togglePlan {
          align-items: center;
          display: flex;
          flex-direction: row;
          margin: 5px 0;

          p {
            font-size: pxToRem(16px);
            font-weight: 400;
          }

          svg {
            margin: 0 10px 0 20px;
            transition: 1s;
            width: 40pt;

            circle,
            path:nth-child(3),
            path:nth-child(1),
            path:nth-child(4) {
              transform: translateX(0);
            }
          }

          &.active {
            svg {

              circle,
              path:nth-child(3),
              path:nth-child(1),
              path:nth-child(4) {
                transform: translateX(30px);
              }
            }
          }
        }
      }

      .title-box {
        font-family: $secundary_font;
        font-size: pxToRem(20px);
        font-weight: 700;
        margin-top: 50px;
      }

      .container-checkbox {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .checkbox-info {
          color: #9F9F9F;
          font-family: $secundary_font;
          font-size: pxToRem(15px);
          margin-bottom: 20px;
          width: 310px;
        }
      }

      .accept-content {
        display: flex;
        justify-content: space-between;
        margin: 299px 0;
        width: 100%;

        .save-button {
          border-radius: 3px;
          color: $black_dark;
          font-family: $secundary_font;
          font-size: pxToRem(15px);
          height: 48px;
          width: 296px;
        }
      }
    }

    .right-create {
      margin: 78px 0 0 105px;
      width: 40%;
    }
  }

  label {
    font-family: $secundary_font;
    font-size: pxToRem(20px);
  }
}
