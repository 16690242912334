@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.ContentSpotify {
  margin-bottom: 40px;
  width: 100%;

  iframe {
    height: 280px;
    width: 100%;
  }
}
