@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.nav-logged-out {
  align-items: center;
  background-color: $blue_magenta;
  display: flex;
  height: 67px;
  justify-content: space-around;
  position: fixed;
  width: 100%;
  z-index: 10;

  h3 {
    color: $white;
    font-size: pxToRem(23px);
    font-weight: 500;
  }

  .Button {
    align-items: center;
    background-color: transparent;
    border: 1px solid $white;
    color: $white;
    display: flex;
    font-size: pxToRem(13px);
    font-weight: 400;
    height: 32px;
    justify-content: center;
    width: 128px;
  }

  @include breakpoint(desktop-small){
    display: none;
  }
}
