@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/function";

.CollectionView {
  .edit-collection-view {
    background-color: $white;
    box-shadow: 0 3px 6px #122F421A;
    display: flex;
    height: 151px;
    justify-content: space-between;
    padding: 104px 0 0 122px;
    position: fixed;
    width: 100%;
    z-index: 9;

    button {
      background: none;
      border: none;
      color: $black_dark;
      cursor: pointer;
      height: 40px;
      outline: transparent;
      padding: 0;
      text-transform: uppercase;
      width: 70px;
    }

    div {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-around;
      padding: 0 0 21px 0;
      width: 90%;

      button {
        font-size: pxToRem(16px);
        font-weight: 500;
        text-align: left;
      }

      h2 {
        font-size: pxToRem(32px);
        font-weight: 600;
        margin-bottom: 20px;
      }

      &:nth-child(2) {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 2px;
        justify-content: center;
        padding-right: 137px;
        padding-top: 10px;
        width: 500px;

        button {
          font-size: pxToRem(22px);
          font-weight: 700;
          margin-left: 75px;
          margin-top: 30px;
          text-transform: unset;

          &:nth-child(1) {
            color: #F41157;
            margin-right: -15px;
          }
        }
      }
    }

    .loader-update {
      height: 40px;
      margin-left: 75px;
      margin-top: 30px;
      text-transform: unset;
      width: 421px;

      div {
        animation: loader 1.4s infinite linear;
        background: $black_dark;
        background: linear-gradient(to right, $black_dark 10%, rgba(255, 255, 255, 0) 42%);
        background: -moz-linear-gradient(left, $black_dark 10%, rgba(255, 255, 255, 0) 42%);
        background: -ms-linear-gradient(left, $black_dark 10%, rgba(255, 255, 255, 0) 42%);
        background: -o-linear-gradient(left, $black_dark 10%, rgba(255, 255, 255, 0) 42%);
        background: -webkit-linear-gradient(left, $black_dark 10%, rgba(255, 255, 255, 0) 42%);
        border-radius: 50%;
        font-size: 10px;
        height: 26px;
        position: relative;
        text-indent: -9em;
        transform: translateZ(0);
        width: 49px;
        -ms-transform: translateZ(0);
        -webkit-animation: loader 1s infinite linear;
        -webkit-transform: translateZ(0);
      }

      div:before {
        background: $black_dark;
        border-radius: 100% 0 0 0;
        content: '';
        height: 50%;
        left: 0;
        position: absolute;
        top: 0;
        width: 50%;
      }

      div:after {
        background: $white;
        border-radius: 50%;
        bottom: 0;
        content: '';
        height: 75%;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 75%;
      }

      @-webkit-keyframes loader {
        0% {
          transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
          -webkit-transform: rotate(360deg);
        }
      }
      @keyframes loader {
        0% {
          transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
          -webkit-transform: rotate(360deg);
        }
      }
    }
  }

  .collection-view-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    height: 100%;
    justify-content: flex-start;
    margin-left: 123px;
    min-height: 40vh;
    padding: 249px 0 100px 0;

    .ContentCard {
      margin-top: 73px;
    }
  }
}
