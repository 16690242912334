@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/fonts";

.DeleteTag {
  align-items: center;
  background-color: #CCED49B3;
  border-radius: 30px;
  border: none;
  color: $black_dark;
  cursor: pointer;
  display: flex;
  font-family: $primary_font_book;
  font-size: pxToRem(16px);
  font-weight: 500;
  height: 29px;
  justify-content: space-evenly;
  margin: 0;
  outline: none;
  padding: 0 11px 0 11px;

  img {
    height: 20px;
    margin-left: 5px;
    width: 16px;
  }
}
