@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/variables";

.FacebookSignIn {
  display: flex;
  justify-content: center;
  margin-top: 11px;

  button {
    background-color: white !important;

    @include breakpoint(desktop-small) {
      background-color: #3B5998 !important;
    }
  }

  .Button {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;

    svg {
      display: none !important;
      margin-right: 10px;
      width: 20px;

      @include breakpoint(desktop-small) {
        display: flex !important;
      }
    }
  }

  div {
    align-items: center;
    background-color: white;
    display: flex;
    margin-right: 0;
  }
}
