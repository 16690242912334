@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/fonts.scss";
@import "../../../../assets/scss/function.scss";

.search-area {
  align-items: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 60vh;
  justify-content: space-around;
  padding-top: 9rem;
  width: 100%;

  h2 {
    font-family: $secundary_font;
    font-size: pxToRem(40px);
    font-weight: 400;
  }

  .search-area-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .search-area-top {
      align-items:  flex-start;
      display: flex;
      justify-content: flex-end;
      margin-top: 75px;
      width: 100%;
    }
  
    .search-area-arrow {
      height: 10%;
      margin: auto;
      z-index: 1;
    }
  }
}
