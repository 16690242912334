@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/function";

.ModalUpdatePhoto {
  align-items: center;
  background: #0000003b 0 0 no-repeat padding-box;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  .container-update-popup {
    background-color: $white;
    border-radius: 10px;
    box-shadow: 50px 50px 99px #2929290F;
    height: 350px;
    width: 80%;

    .header-update-popup {
      display: flex;
      justify-content: flex-end;

      .exit-container-update {
        background-color: unset;
        background-image: url("../../../../assets/images/blue-exit-icon.svg");
        background-size: cover;
        border: none;
        cursor: pointer;
        height: 70px;
        margin: 23px 17px 0 0;
        outline: none;
        width: 70px;
      }
    }

    .body-update-popup {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-top: 24px;

      .LoaderButton {
        margin: 0;
        width: 100%;
      }

        input {
          outline: none;

          &.UploadButton {
            display: flex;
            height: 48px;
            width: 100%;
          }

          &.UploadButton::-webkit-file-upload-button {
            border: 0;
            height: 0;
            padding: 0;
            width: 180px;
          }

          &.UploadButton::before {
            align-items: center;
            background: #CFEC59;
            box-shadow: 0 3px 6px #122F421A;
            box-sizing: border-box;
            color: $black_dark;
            content: '+ Carregar Foto';
            cursor: pointer;
            display: flex;
            flex-direction: column;
            font-family: Grotesk, sans-serif;
            font-size: pxToRem(20px);
            font-weight: 700;
            height: 48px;
            justify-content: center;
            outline: none;
            white-space: nowrap;
            width: 100%;
            -webkit-user-select: none;
          }

          @include breakpoint(desktop-small) {
            width: 399px;

            &.UploadButton {
              width: 399px;
            }
          }
        }

      img {
        border-radius: 50%;
        height: 86px;
        margin-bottom: 28px;
        width: 86px;
      }

      h2 {
        font-family: Grotesk, sans-serif;
        font-size: pxToRem(36px);
        font-weight: 600;
        margin-bottom: 55px;
      }

      .Button {
        background-color: white;
        border-radius: 0;
        box-shadow: none;
        color: grey;
        cursor: not-allowed;
        height: 48px;
        margin-top: 25px;
        width: 90%;

        @include breakpoint(desktop-small) {
          width: 399px;
        }
      }
    }

    @include breakpoint(desktop-small) {
      height: 458px;
      width: 982px;
    }
  }
}
