@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.ContentCardList {
  align-items: center;
  background: $white;
  display: flex;
  flex-direction: column;

  h1 {
    color: black;
    font-size: pxToRem(36px);
    font-weight: 500;
    margin-bottom: 69px;
  }

  .cards-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0 18px;
    justify-content: flex-start;
    padding-bottom: 100px;
    width: 87%;

    .ContentCard {
      margin-bottom: 22px;
    }

    &.fetching {
      .fetching-box {
        animation: shimmerAnim 2s infinite linear;
        background-image: linear-gradient(
          to right,
          #eeeeee 40%,
          #dddddd 50%,
          #eeeeee 60%
        );
        box-shadow: 0 1px 3px #00000057;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 606px;
        justify-content: space-between;
        margin-top: 50px;
        width: 397px;

        @keyframes shimmerAnim {
          0% {
            background-position: -868px 0;
          }
          100% {
            background-position: 868px 0;
          }
        }
      }
    }
  }
}
