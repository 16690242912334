@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/variables";

.GoogleSignIn {
  display: flex;
  justify-content: center;

  button {
    align-items: center !important;
    border: 1px solid #DCDCDC !important;
    border-radius: 25px !important;
    box-shadow: 0 3px 6px white !important;
    color: $black_dark !important;
    cursor: pointer;
    display: flex;
    font-family: Grotesk, sans-serif !important;
    font-size: pxToRem(15px) !important;
    font-weight: 500 !important;
    height: 56px;
    justify-content: center;
    width: 300px;

    div {
      align-items: center !important;
      background-color: transparent !important;
      display: flex;
      margin-right: 0 !important;
    }

    @include breakpoint(desktop-small) {
      border: 2px solid $white !important;
      box-shadow: 0 3px 6px #122F421A !important;
      color: grey !important;
      font-size: pxToRem(20px) !important;
      font-weight: 600 !important;
      height: 48px;
      width: 399px;
    }
  }
}
