@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.ContentScreen {
  display: initial;
  padding-top: 104px;
  position: relative;

  .LoginHome {
    margin: 88px 0 107px 0;
  }

  @include breakpoint(desktop-small) {
    display: flex;
  }

  .author-options {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: absolute;
    right: 100px;
    top: 232px;
    width: max-content;

    .ContentIcon {
      position: relative;
      z-index: 0;

      &.green {
        background: linear-gradient(to left, #579844 50%, #fff 50%);
        background-position: bottom left;
        background-size: 200% 100%;
      }

      &.red {
        background: linear-gradient(to left, #e45357 50%, #fff 50%);
        background-position: bottom left;
        background-size: 200% 100%;
      }

      &:hover {
        background-position: bottom right;
        color: #fff;
        cursor: pointer;
        z-index: 2;
      }
    }
  }

  .PopUp {
    p {
      margin-bottom: 30px;
    }

    .buttons {
      display: flex;
      gap: 10px;
    }
  }
}
