@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.SearchResult {
  width: 100%;

  .result-tags {
    align-items: center;
    background: $black;
    display: flex;
    justify-content: space-between;
    padding: 141px 0 27px 0;
    position: fixed;
    width: 100%;
    z-index: 1;

    .tags-bar {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      max-width: 1120px;
      padding-left: 96px;

      .Tag {
        cursor: unset;

        &:hover, &:focus {
          background: none;
        }
      }
    }

    .clear-button {
      background-color: unset;
      border: none;
      color: $blue_dark_button;
      cursor: pointer;
      font-family: Grotesk, sans-serif;
      font-size: pxToRem($tag_size);
      font-weight: 400;
      height: 30px;
      padding-right: 104px;
      width: 221px;

      &:focus {
        outline: none;
      }
    }
  }

  .cards-search {
    padding: 95px 0 0 0;

    .ContentCardList {
      h1 {
        display: none;
      }

      .cards-list {
        width: 100%;
      }
    }

    @include breakpoint(desktop-small) {
      padding: 254px 0 0 0;
    }
  }
}
