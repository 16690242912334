@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.SearchResultList {
  display: flex;
  justify-content: flex-start;

  h1 {
    font-size: pxToRem(25px);
    font-weight: 600;
    width: 683px;
  }

  .container-result {
    min-height: 80vh;
    width: 100%;

    h1 {
      text-align: center;

      @include breakpoint(desktop-small) {
        text-align: initial;
      }
    }

    .expand-contents {
      display: none;
      justify-content: center;
      margin: 20px 0;
      width: 100%;

      button {
        align-self: center;
        background-color: $blue_magenta;
        border: none;
        border-radius: 30px;
        color: white;
        cursor: pointer;
        font-size: pxToRem($search_tag);
        height: 45px;
        margin: 0;
        outline: none;
        width: 200px;
      }

      @include breakpoint(desktop-small) {
        display: flex;
      }
    }

    .expand-mobile {
      margin-bottom: 120px;

      button {
        align-items: center;
        background-color: $light_green;
        border: none;
        border-radius: 30px;
        color: $black_dark;
        cursor: pointer;
        display: flex;
        font-size: pxToRem($small_mobile_text);
        height: 35px;
        justify-content: center;
        margin: 0;
        outline: none;
        width: 150px;
      }

      @include breakpoint(desktop-small) {
        button {
          display: none;
        }
      }
    }

    .home-tag {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: left;
      padding-left: 27px;
      position: relative;
      right: 5px;
      top: -118px;

      .follow-tag {
        align-items: center;
        display: flex;

        .Button {
          align-items: center;
          border: 1px solid $black_dark;
          color: black;
          display: flex;
          font-family: Grotesk, sans-serif;
          font-size: pxToRem(13px);
          height: 31px;
          justify-content: center;
          margin-left: 20px;
          width: 85px;

          &:nth-child(2) {
            background-color: $white;
            box-shadow: none;
          }
        }

        .LoaderButton {
          align-items: center;
          border-radius: 24px;
          box-shadow: 0 3px 6px #122f421a;
          color: black;
          display: flex;
          height: 31px;
          justify-content: center;
          margin: 0 0 0 20px;
          width: 85px;
        }

        .twoLoader {
          background-color: white;
          box-shadow: none;

          div:after {
            background-color: $white;
          }
        }
      }

      p {
        font-size: pxToRem(31px);
        font-weight: 600;
      }

      .references-tag {
        display: flex;
        padding-top: 39px;

        p {
          font-size: pxToRem(16px);
          font-weight: 400;

          &:nth-child(2) {
            margin-left: 56px;
          }
        }
      }

      @include breakpoint(desktop-small) {
        p {
          font-size: pxToRem(40px);
          font-weight: 600;
        }

        .references-tag {
          p {
            font-size: pxToRem(23px);

            &:nth-child(2) {
              margin-left: 81px;
            }
          }
        }

        .follow-tag {
          .Button {
            align-items: center;
            display: flex;
            font-size: pxToRem(20px);
            height: 38px;
            justify-content: center;
            margin: 0 0 0 20px;
            padding: 0;
            width: 128px;
          }

          .LoaderButton {
            height: 38px;
            justify-content: center;
            margin: 0 0 0 20px;
            width: 128px;
          }
        }
      }
    }

    .cards-search-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 1729px;
      padding-top: 21px;

      > p {
        font-size: 28px;
      }

      &.render-tag {
        position: relative;
        top: -95px;
      }

      .ContentCard {
        margin: 0 0 73px 18px;
      }

      .CardSlide {
        margin: 0 0 46px 0;
      }

      &.fetching {
        padding-top: 0;

        .fetching-box {
          margin: 0 0 73px 18px;
          animation: shimmerAnim 2s infinite linear;
          background-image: linear-gradient(
            to right,
            #eeeeee 40%,
            #dddddd 50%,
            #eeeeee 60%
          );
          box-shadow: 0 1px 3px #00000057;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          height: 606px;
          justify-content: space-between;
          margin-top: 23px;
          width: 397px;

          @include breakpoint(desktop-small) {
            display: flex;
            height: 490px;
            width: 325px;

            .card-type {
              width: 250px;
            }
          }

          @include breakpoint(desktop-large) {
            height: 606px;
            width: 397px;

            .card-type {
              width: 360px;
            }

            .card-infos {
              box-sizing: unset;
            }
          }

          @keyframes shimmerAnim {
            0% {
              background-position: -868px 0;
            }
            100% {
              background-position: 868px 0;
            }
          }
        }
      }

      @include breakpoint(desktop-small) {
        justify-content: initial;

        &.render-tag {
          position: relative;
          top: -66px;
        }

        .CardSlide {
          display: none;
        }
      }
    }

    @include breakpoint(desktop-small) {
      padding-left: 70px;
    }
  }

  @include breakpoint(desktop-small) {
    padding-top: 60px;
  }
}
