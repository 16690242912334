@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.ContentIcon {
  align-items: center;
  background: linear-gradient(to left, #0505BE 50%, #fff 50%);
  background-position: bottom left;
  background-size: 200% 100%;
  border-radius: 30px;
  display: flex;
  height: 52px;
  transition: all .3s ease-in-out;
  width: max-content;

  p {
    color: white;
    font-weight: 700;
    padding: 0 8px 0 14px;
  }

  button {
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    height: 52px;
    outline: none;
    padding: 0;
    width: 52px;

    &:hover {
      cursor: pointer;
      outline: none;
      transition: width 0.4s;
    }
  }

  &:hover {
    background-position: bottom right;
    color: #fff;
    cursor: pointer;
  }
}
