@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.DatePickerSearch {
  *:focus {
    outline: none;
  }

  .react-datepicker {
    background-color: white;
    border: none;

    .react-datepicker__header {
      border: none;
    }

    .react-datepicker__day-names {
      background-color: white;
      border: none;
    }

    .react-datepicker__current-month {
      display: none;
    }

    .react-datepicker__navigation--previous {
      display: none;
    }

    .react-datepicker__navigation--next {
      display: none;
    }

    .react-datepicker__header {
      padding: 0;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      color: #000;
      display: inline-block;
      line-height: 39px;
      margin: 3px;
      text-align: center;
      width: 37px;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
      background-color: #1a73e8;
      color: white;
      font-weight: bold;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
      background-color: #1a73e8;
    }

    .react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
    .react-datepicker__quarter--selected,
    .react-datepicker__quarter--in-selecting-range,
    .react-datepicker__quarter--in-range {
      background-color: #1a73e8;
      border-radius: 30px;
      color: #fff;
    }

    .react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
    .react-datepicker__quarter--selected:hover,
    .react-datepicker__quarter--in-selecting-range:hover,
    .react-datepicker__quarter--in-range:hover {
      background-color: #1a73e8;
    }

    .react-datepicker__day--today,
    .react-datepicker__month-text--today,
    .react-datepicker__quarter-text--today,
    .react-datepicker__year-text--today {
      font-weight: initial;
    }

    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      background-color: #1a73e8;
      border-radius: 30px;
      color: #fff;
    }

    .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
    .react-datepicker__month-text--selected:hover,
    .react-datepicker__month-text--in-selecting-range:hover,
    .react-datepicker__month-text--in-range:hover,
    .react-datepicker__quarter-text--selected:hover,
    .react-datepicker__quarter-text--in-selecting-range:hover,
    .react-datepicker__quarter-text--in-range:hover,
    .react-datepicker__year-text--selected:hover,
    .react-datepicker__year-text--in-selecting-range:hover,
    .react-datepicker__year-text--in-range:hover {
      background-color: #1a73e8;
      color: #fff;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      background-color: #1a73e8;
      border-radius: 30px;
      color: #fff;
    }

    .react-datepicker__day--keyboard-selected:hover,
    .react-datepicker__month-text--keyboard-selected:hover,
    .react-datepicker__quarter-text--keyboard-selected:hover,
    .react-datepicker__year-text--keyboard-selected:hover {
      background-color: #1a73e8;
    }

    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
      border-radius: 30px;
    }

    .react-datepicker__day--in-selecting-range ,
    .react-datepicker__month-text--in-selecting-range ,
    .react-datepicker__quarter-text--in-selecting-range ,
    .react-datepicker__year-text--in-selecting-range {
      background-color: #d2e3fc;
      color: #000;
    }

    .react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
    .react-datepicker__quarter-text.react-datepicker__month--selected:hover,
    .react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
    .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
    .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
      background-color: #1a73e8;
      color: #fff;
    }

    .react-datepicker__close-icon::after {
      background-color: #1a73e8;
      border-radius: 50%;
      color: #fff;
      content: "\00d7";
      cursor: pointer;
      display: table-cell;
      font-size: 12px;
      height: 16px;
      line-height: 1;
      padding: 2px;
      text-align: center;
      vertical-align: middle;
      width: 16px;
    }
  }
}
