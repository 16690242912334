@mixin font-face($font-family, $font-suffix, $font-weight, $font-style) {
  @font-face {
    font-family: $font-family;
    src: url($font-suffix) format("truetype"),
    url($font-suffix) format("opentype");
    font-weight: $font-weight;
    font-style: $font-style;
  }
}

@include font-face(
  Grotesk,
  "../../assets/fonts/WorkSans-Regular.ttf",
  400,
  normal
);

@include font-face(
  Grotesk,
  "../../assets/fonts/WorkSans-Medium.ttf",
  500,
  normal
);

@include font-face(
  Grotesk,
  "../../assets/fonts/WorkSans-SemiBold.ttf",
  600,
  normal
);

@include font-face(
  Grotesk,
  "../../assets/fonts/WorkSans-Bold.ttf",
  700,
  normal
);

@include font-face(
  Roboto,
  "../../assets/fonts/Roboto-Bold.ttf",
  200,
  normal
);

@include font-face(
  Roboto,
  "../../assets/fonts/Roboto-Regular.ttf",
  100,
  normal
);

@include font-face(
  SpaceGrotesk,
  "../../assets/fonts/SpaceGrotesk-Regular.ttf",
  400,
  normal
);

@include font-face(
  SpaceGrotesk,
  "../../assets/fonts/SpaceGrotesk-Bold.ttf",
  700,
  normal
);
