@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/function";

.ZoomButton {
  align-items: center;
  background-color: $light_green;
  border-radius: 45px;
  border: none;
  color: $light_black;
  cursor: pointer;
  display: flex;
  font-family: $secundary_font;
  font-size: pxToRem(20px);
  font-weight: 700;
  height: 44px;
  justify-content: center;
  width: 145px;

  &:focus {
    outline: none;
  }
}
