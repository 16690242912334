@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/fonts";

.NavbarMobile {
  align-items: center;
  background-color: $white;
  box-shadow: 0 3px 6px #0000000A;
  display: flex;
  height: 67px;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 15;

  p {
    font-size: pxToRem(23px);
    font-weight: 500;
    letter-spacing: 0.37px;
    text-align: center;
  }

  button {
    background: url("../../../assets/images/icon-search.svg") no-repeat center;
    border: none;
    height: 100%;
    outline: none;
    width: 86px;

    &:nth-of-type(1) {
      background-image: unset;
      border: none;
      height: 30px;
      margin: 0;
      width: 86px;
      z-index: 11;

      &:before, &:after {
        background-color: $black_dark;
        content: ' ';
        height: 15px;
        left: 30px;
        position: absolute;
        width: 1px;
      }

      &:before {
        bottom: 19px;
        transform: rotate(-49deg);
      }

      &:after {
        top: 24px;
        transform: rotate(-127deg);
      }
    }
  }

  @include breakpoint(desktop-small) {
    display: none;
  }
}

.search-navbar {
  background-color: $white;
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 70px;
  position: fixed;
  top: 0;
  transition: visibility 0s, opacity 0.4s linear;
  width: 100%;
  z-index: 10;

  .search-nav-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 90px 25px 0 25px;

    input {
      border: 0;
      border-bottom: 1px solid $black_dark;
      border-radius: 0;
      font-family: Grotesk, sans-serif;
      font-size: pxToRem(18px);
      font-weight: 400;
      height: 24px;
      margin-top: 120px;
      outline: none;
      padding: 0 0 8px 0;
      text-align: center;
      width: 100%;

      &::placeholder {
        color: $black_dark;
        font-size: pxToRem(18px);
        letter-spacing: -0.15px;
        opacity: 0.4;
        text-align: center;
      }
    }

    .Button {
      border: none;
      box-shadow: none;
      color: $black_dark;
      font-size: pxToRem(15px);
      font-weight: 500;
      height: 37px;
      margin-top: 30px;
      position: initial;
      width: 191px;
    }

    .search-nav-tags {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      padding-bottom: 100px;
      width: 100%;

      h4 {
        font-family: Roboto, sans-serif;
        font-size: pxToRem(16px);
        font-weight: 200;
        letter-spacing: -0.13px;
        margin: 85px 0 30px 0;
      }

      p {
        margin-top: 12px;
      }
    }
  }
}
