@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/fonts";

.loginContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 92%;

  .Login {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 99%;
    width: 100%;

    &.fetching-login {
      .Input {
        opacity: 0.5;
      }
    }

    .buttonsBox {
      display: flex;
      flex-direction: column;

      .select-entry {
        border-bottom: 1px solid black;
        display: flex;
        width: 100%;

        @include breakpoint(desktop-small) {
          display: none;
        }
      }

      h2 {
        font-size: pxToRem(31px);
        font-weight: 700;
        letter-spacing: -1.83px;
        text-align: left;
        display: none;

        @include breakpoint(desktop-small) {
          display: flex;
        }
      }

      h3 {
        font-size: pxToRem(36px);
        font-weight: 500;
        letter-spacing: -1.83px;
        text-align: center;
        width: 100%;

        @include breakpoint(desktop-small) {
          display: none;
        }
      }

      .Button {
        border: 1px solid #DCDCDC;
        font-family: $primary_font_book;
        font-size: pxToRem(15px);
        font-weight: 500;
        height: 56px;
        margin-left: -3px;
        width: 300px;

        &:nth-of-type(1) {
          background: white url("../../../assets/images/facebook-square-brands.svg") no-repeat 15%;
          border-radius: 25px;
          box-shadow: 0 3px 6px white;
          color: $black_dark;
          letter-spacing: 0.1px;
          margin-top: 11px;
          padding-left: 68px;
        }

        &:nth-of-type(3) {
          border-radius: 0;
          color: black;
          font-size: pxToRem(20px);
          font-weight: 700;
          letter-spacing: 0;
          margin: 48px 0 0 1px;
        }

        @include breakpoint(desktop-small) {
          font-size: pxToRem(20px);
          font-weight: 400;
          height: 48px;
          letter-spacing: -1.83px;
          margin-left: -3px;
          width: 400px;

          &:nth-of-type(1) {
            background: #385185 url("../../../assets/images/facebook.png") no-repeat 20%;
            box-shadow: 0 3px 6px #122f421a;
            color: white;
            padding: 0;
          }
        }
      }

      @include breakpoint(desktop-small) {
        height: 220px;
        justify-content: space-between;
        width: 400px;
      }
    }

    .screen-splitter {
      align-items: center;
      display: flex;
      gap: 6px;
      height: 14px;
      margin: 20px 0;

      span {
        background-color: rgba(212, 208, 208, 0.87);
        height: 2px;
        opacity: 0.5;
        padding: 0;
        transition: transform 0.3s ease;
        width: 122px;
      }

      p {
        background-color: white;
        color: black;
        font-size: pxToRem(16px);
        font-weight: 700;
        margin-left: 10px;
        text-transform: uppercase;
        width: 35px;
      }

      @include breakpoint(desktop-small) {
        height: 100px;
        margin-bottom: 0;

        span {
          background-color: $black_dark;
          height: 1px;
          width: 175px;
        }
      }
    }

    form {
      .inputsBox {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .Input {
          border-radius: 13px;
          margin: 0 0 5px 0;
          width: 300px;

          input {
            background-color: #F2F2F2;
            border-radius: 13px;
            border: none;
            height: 56px;
            width: 290px;
          }

          &:nth-of-type(2) {
            margin-bottom: 0;

            @include breakpoint(desktop-small) {
              margin-bottom: 18px;
            }
          }

          @include breakpoint(desktop-small) {
            input {
              height: 46px;
              width: 300px;
            }
          }
        }

        @include breakpoint(desktop-small) {
          width: 400px;
          align-items: flex-start;

          .Input {
            border-radius: 0;
            height: 48px;
            margin: 0 0 35px 0;
            width: 399px;

            input {
              background-color: $white;
              border-radius: 0;
              border: 1px solid #222222;
              width: 387px;
            }
          }
        }
      }
    }

    .container-forgot {
      text-align: end;
      position: relative;
      width: 300px;

      .Button {
        background: none;
        border-radius: 0;
        box-shadow: none;
        color: #0505BE;
        cursor: pointer;
        display: none;
        font-family: Grotesk, sans-serif;
        font-size: pxToRem(16px);
        font-weight: 600;
        height: 0px;
        justify-content: flex-end;
        letter-spacing: 0.4px;
        outline: none;
        padding: 0 0 0 50px;
        position: relative;
        text-decoration: none;
        top: -129px;
        width: 100%;

        &:nth-child(1) {
          color: #b5b4b4;
          display: flex;
          font-size: pxToRem(13px);
          font-weight: 500;
          justify-content: flex-start;
          padding: 5px 0 0 13px;
          position: relative;
          width: 100%;
        }

        @include breakpoint(desktop-small) {
          display: flex;
          left: 47px;
          padding: 0;
          position: relative;
          text-transform: uppercase;
          top: -182px;

          &:nth-child(1) {
            display: none;
          }
        }
      }
    }

    .entryContent {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 25%;
      margin-top: 75px;

      .Button {
        border-radius: 13px;
        color: $black;
        font-size: pxToRem(17px);
        font-weight: 500;
        height: 56px;
        width: 300px;
      }

      .registration-login {
        align-items: center;
        color: black;
        display: none;
        justify-content: center;

        .Button {
          background: transparent;
          box-shadow: none;
          color: black;
          font-size: pxToRem(20px);
          font-weight: 700;
          height: 60px;
          letter-spacing: 0.02px;
          margin-left: 8px;
          padding: 0;
          text-decoration: underline;
          width: 53px;
        }

        p {
          font-size: pxToRem(20px);
          font-weight: 700;
        }
      }

      .LoaderButton {
        border-radius: 13px;
        height: 56px;
        margin: 0;
        padding: 0;
        width: 300px;

        @include breakpoint(desktop-small) {
          border-radius: 0;
          box-shadow: 0 3px 6px #122F421A;
          color: $black;
          font-size: pxToRem(22px);
          font-weight: 700;
          height: 65px;
          margin-top: -18px;
          width: 100%;
        }
      }

      @include breakpoint(desktop-small) {
        display: flex;
        justify-content: flex-end;
        margin-top: 89px;

        .Button {
          border-radius: 0;
          color: $black;
          font-size: pxToRem(22px);
          font-weight: 700;
          height: 48px;
          width: 100%;
        }

        .registration-login {
          display: flex;
        }
      }
    }
  }

  @include breakpoint(desktop-small) {
    background-color: $white;
    border-radius: 5px;
    height: 837px;
    margin-top: 5%;
    max-height: 90%;
    position: fixed;
    width: 630px;
  }
}
