@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.MyContents {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  min-height: calc(100vh - 695px);
  padding: 154px 0 66px;
  width: 100%;

  @include breakpoint(desktop-small) {
    gap: unset;
    justify-content: flex-start;
    padding: 200px 30px 64px 30px;
    box-sizing: border-box;
    gap: 20px;
  }

  .no-content {
    margin-top: 40px;
    text-align: center;
    width: calc(100% - 668px);
  }

  .content-list-fetching {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    height: 100%;
    justify-content: center;
    min-height: 40vh;
    padding: 0 0 0 0;

    @include breakpoint(desktop-small) {
      justify-content: flex-start;
    }
  }

  .expand-contents {
    display: none;
    justify-content: center;
    margin: 20px 0;
    width: 100%;

    button {
      align-self: center;
      background-color: $blue_magenta;
      border-radius: 30px;
      border: none;
      color: white;
      cursor: pointer;
      font-size: pxToRem($search_tag);
      height: 45px;
      margin: 0;
      outline: none;
      width: 200px;
    }

    @include breakpoint(desktop-small) {
      display: flex;
    }
  }

  .expand-mobile {
    margin-bottom: 120px;

    button {
      align-items: center;
      background-color: $light_green;
      border-radius: 30px;
      border: none;
      color: $black_dark;
      cursor: pointer;
      display: flex;
      font-size: pxToRem($small_mobile_text);
      height: 35px;
      justify-content: center;
      margin: 0;
      outline: none;
      width: 150px;
    }

    @include breakpoint(desktop-small) {
      button {
        display: none;
      }
    }
  }
}
