@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/function";

.NotesTitle {
  align-items: flex-end;
  display: flex;
  flex-direction: column;

  .notes-title-container {
    align-items: flex-end;
    border-left: 2px solid #efeeee;
    display: flex;
    flex-direction: column;
    margin-left: 21px;
    padding-bottom: 27px;
    width: 918px;

    .blue-marker {
      position: relative;

      span {
        background-color: #0505BE;
        border-radius: 10px 10px;
        cursor: initial;
        height: 46px;
        left: -922px;
        position: absolute;
        top: 2px;
        width: 6px;
      }
    }

    .notes-suggestion-head {
      align-items: center;
      background: #F8F8F8 0 0 no-repeat padding-box;
      border-radius: 12px;
      cursor: pointer;
      display: flex;
      height: 53px;
      justify-content: space-between;
      margin-bottom: 16px;
      width: 889px;

      .notes-suggestion-title {
        max-height: 34px;
        max-width: 667px;
        overflow: hidden;
        text-overflow: ellipsis;

        p {
          display: -webkit-box;
          font-family: Grotesk, sans-serif;
          font-size: pxToRem(26px);
          font-weight: 400;
          padding-left: 33px;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
      }

      .notes-suggestion-icon {
        align-items: center;
        display: flex;
        padding-left: 61px;

        p {
          color: black;
          font-family: Grotesk, sans-serif;
          font-size: pxToRem(16px);
          font-weight: 700;
          padding: 0 35px 0 12px;
        }

        svg {
          height: 35px;
          width: 35px;
        }
      }
    }
  }
}
