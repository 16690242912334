@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/fonts";

.LoginHome {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 0 60px 0;

  p {
    font-size: pxToRem(17px);
    font-weight: 700;
    margin-bottom: 21px;
  }

  .FacebookSignIn {
    margin: 0;
  }

  .Button {
    background: white url("../../../assets/images/email.svg") no-repeat 21%;
    border: 1px solid #DCDCDC;
    border-radius: 25px;
    box-shadow: 0 3px 6px white;
    color: $black_dark;
    font-family: Grotesk, sans-serif;
    font-size: pxToRem(15px);
    font-weight: 500;
    height: 56px;
    letter-spacing: 0;
    margin-left: -3px;
    margin-top: 12px;
    padding-left: 68px;
    width: 300px;

    &:nth-child(1) {
      background: white url("../../../assets/images/facebook-square-brands.svg") no-repeat 15%;
      border-radius: 25px;
      box-shadow: 0 3px 6px white;
      color: $black_dark;
      letter-spacing: 0.1px;
      margin-top: 12px;
      padding-left: 68px;
    }
  }
}
