@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/function";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/fonts";

.NewPassword {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;

  .error {
    display: none;

    &.visible {
      color: black;
      display: flex;
      margin-top: 50px;
      text-align: left;
      width: 381px;
    }
  }

  h2 {
    display: none;
    font-size: pxToRem(31px);
    font-weight: 700;
    letter-spacing: -1.99px;
    padding-bottom: 35px;
    text-align: left;

    @include breakpoint(desktop-small) {
      display: flex;
      width: 100%;
    }
  }

  h3 {
    display: flex;
    text-align: left;
    width: 99%;

    @include breakpoint(desktop-small) {
      display: none;
    }
  }

  .Input {
    border-radius: 13px;
    margin: 0;
    width: 300px;

    p {
      padding: 10px 0 0 3px;
    }

    input {
      background-color: $white_smoke;
      border: none;
      border-radius: 13px;
      height: 56px;
      width: 290px;

      &:nth-of-type(1) {
        margin-top: 14px;
      }
    }

    @include breakpoint(desktop-small) {
      border-radius: 0;
      width: 399px;

      input {
        background-color: $white;
        border: 1px solid $erie_black;
        border-radius: 0;
        height: 48px;
        width: 387px;
      }
    }
  }

  .Button {
    border-radius: 33px;
    color: $black;
    font-size: pxToRem(17px);
    font-weight: 500;
    height: 37px;
    margin-left: 0;
    margin-top: 19px;
    width: 182px;

    &:nth-child(1) {
      box-shadow: none;
      color: $black;
      font-size: pxToRem(17px);
      font-weight: 500;
      height: 48px;
      margin-top: 8px;
      outline: none;
      padding: 0;
    }

    @include breakpoint(desktop-small) {
      border-radius: 0;
      color: black;
      font-size: pxToRem(20px);
      font-weight: 700;
      height: 48px;
      text-transform: capitalize;
      width: 100%;

      &:nth-child(1) {
        box-shadow: none;
        font-size: pxToRem(20px);
        font-weight: 700;
        height: 48px;
        letter-spacing: 0.02px;
        margin-top: 20px;
        outline: none;
        padding: 0;
        width: 390px;
      }
    }
  }

  .LoaderButton {
    border-radius: 33px;
    box-shadow: 0 3px 6px #122f421a;
    height: 37px;
    margin: 19px 0 0 0;
    width: 182px;

    @include breakpoint(desktop-small) {
      align-items: center;
      background-color: #CFEC59;
      border-radius: 0;
      contain: size;
      display: flex;
      height: 48px;
      justify-content: center;
      margin: 20px 0 0 0;
      outline: transparent;
      width: 399px;
    }
  }
}
