@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/function";

.History {
  display: flex;
  padding-top: 299px;

  .history-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 0 127px 0 91px;
    width: 78%;

    .loading {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 30px 0;
      font-size: 40px;
      color: $strong_blue;
    }

    .alert-history {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;
    }

    .container-fetching-history {
      display: flex;
      flex-wrap: wrap;
      gap: 19px;
      height: 100%;
      justify-content: flex-start;
      min-height: 40vh;
      padding: 34px 0 200px 0;
    }
  }
}
