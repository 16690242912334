@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.ContentCardListMobile {
  margin: 15px 0 15px 0;

  .ContentCardMobile {
    margin-top: 15px;
  }

  .fetching-card-mobile {
    animation: shimmerAnim 2s infinite linear;
    background-image: linear-gradient(to right, #eeeeee 40%, #dddddd 50%, #eeeeee 60%);
    box-shadow: 0 1px 3px #00000057;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 121px;
    justify-content: space-between;
    margin-top: 15px;
    padding: 22px 0 29px 0;

    @keyframes shimmerAnim {
      0% {
        background-position: -868px 0;
      }
      100% {
        background-position: 868px 0;
      }
    }
  }
}
