@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/function";
@import "../../../../assets/scss/mixins";

.CollectionList {
  min-height: 100vh;
  padding: 270px 134px 64px 124px;

  .create-collections {
    align-items: flex-end;
    display: flex;
    margin-bottom: 100px;

    h2 {
      font-family: $secundary_font;
      font-size: pxToRem(30px);
      margin-right: 100px;
    }

    button {
      align-items: center;
      background-color: $blue_magenta;
      border-radius: 42px;
      border: none;
      color: $white;
      cursor: pointer;
      display: flex;
      font-family: $secundary_font;
      font-size: pxToRem(20px);
      height: 71px;
      justify-content: space-around;
      width: 223px;

      &:focus {outline:0;}
    }
  }

  .list-collections {
    display: flex;
    flex-wrap: wrap;
    gap: 100px 19px;
    padding-bottom: 100px;
    width: 100%;
  }

  .GenericCreationCard .text-journey-list {
      border-top: 1px solid #f6f5f5;
      color: #79ACD9;
      font-size: 1.25rem;
      font-weight: 700;
      letter-spacing: 0.02px;
      margin-top: 9px;
      padding: 21px 0 0 0;
      text-align: center;
      text-transform: capitalize;
      width: 312px;
    }
}
