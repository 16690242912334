@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/fonts";

.CreatePlaylist {
  background-color: $white;
  border-radius: 10pt;
  height: 100%;
  overflow: scroll;
  position: fixed;
  top: 24px;
  transition: visibility 0s, opacity 0.5s ease-in-out;
  width: 100%;
  z-index: 15;

  .exit-entry-mobile {
    background: transparent;
    border: none;
    height: 30px;
    margin: 0;
    position: absolute;
    right: 22px;
    top: 11px;
    width: 30px;
    z-index: 11;

    &:before, &:after {
      background-color: $black_dark;
      content: ' ';
      height: 20px;
      left: 15px;
      position: absolute;
      width: 1px;
    }

    &:before {
      transform: rotate(-90deg)
    }

    &:after {
      display: none;
    }

    @include breakpoint(desktop-small) {
      display: none;
    }
  }

  .create-playlist-container {
    height: 80%;
    padding: 27px 0 20px 28px;

    p {
      color: $black_dark;
      font-size: pxToRem($small_mobile_text);
      font-size: pxToRem(16px);
      font-weight: 600;
    }

    .create-playlist-input {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-top: 93px;
      width: 91%;

      input {
        border: 0;
        border-bottom: 2px solid $gray_input;
        border-radius: 0;
        font-family: Grotesk, sans-serif;
        font-size: pxToRem(18px);
        font-weight: 400;
        height: 24px;
        outline: none;
        padding-bottom: 8px;
        text-align: center;
        width: 100%;

        &::placeholder {
          color: $black_dark;
          font-size: pxToRem(18px);
          letter-spacing: -0.15px;
          opacity: 0.4;
          text-align: center;
        }
      }

      .Button {
        align-items: center;
        background-color: $light_green;
        border-radius: 33px;
        color: $black;
        cursor: auto;
        font-size: pxToRem($reading_size);
        font-weight: 500;
        height: 37px;
        justify-content: center;
        margin-top: 40px;
        transition: opacity 0.5s ease-out;
        width: 182px;
      }

      .LoaderButton {
        border-radius: 33px;
        height: 37px;
        margin-top: 40px;
        width: 182px;
      }
    }
  }
}
