@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/function";

.CreateCollectionPopUp {
  align-items: center;
  background: #0000003b 0 0 no-repeat padding-box;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  .container-collection-popup {
    background-color: $white;
    border-radius: 10px;
    box-shadow: 50px 50px 99px #2929290F;
    height: 458px;
    width: 982px;

    .header-collection-popup {
      display: flex;
      justify-content: flex-end;

      .exit-container-collection {
        background-color: unset;
        background-image: url("../../../../assets/images/blue-exit-icon.svg");
        background-size: cover;
        border: none;
        cursor: pointer;
        height: 70px;
        margin: 23px 17px 0 0;
        outline: none;
        width: 70px;
      }
    }

    .body-collection-popup {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-top: 24px;

      h2 {
        color: $black_dark;
        font-size: pxToRem(36px);
        font-weight: 600;
      }

      p {
        color: $black_dark;
        font-size: pxToRem(22px);
        font-weight: 500;
        margin-top: 16px;
      }

      .Input {
        margin-top: 35px;

        input {
          border: 1px solid #D8D8D8;
          border-radius: 0;
          height: 51px;
          padding-left: 10px;
          width: 384px;

          &:focus {
            border: 1px solid $black_dark;
          }

          &::placeholder {
            font-family: $primary_font_book;
            font-size: pxToRem(20px);
            font-weight: 400;
            letter-spacing: 0.02px;
            margin-left: 20px;
          }
        }
      }

      .Button {
        border-radius: 0;
        color: $black_dark;
        margin-top: 25px;
      }

      .error {
        opacity: 0;

        &.visible {
          color: black;
          opacity: 1;
          text-align: center;
        }
      }
    }
  }
}
