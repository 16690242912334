@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.SeeLater {
  display: flex;
  padding: 130px 0 66px;

  .seelater-view-cards {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    min-height: 80vh;
    padding: 0;
    width: 100%;

    .ContentCard {
      margin: 73px 14px 0 0;
    }

    .ContentCardMobile {
      margin-bottom: 17px;
      width: 100%;
    }

    .favorite-fetching {
      animation: shimmerAnim 2s infinite linear;
      background-image: linear-gradient(to right, #eeeeee 40%, #dddddd 50%, #eeeeee 60%);
      box-shadow: 0 1px 3px #00000057;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      height: 170px;
      justify-content: space-between;
      margin-bottom: 17px;
      width: 100%;

      @keyframes shimmerAnim {
        0% {
          background-position: -868px 0;
        }
        100% {
          background-position: 868px 0;
        }
      }
    }

    @include breakpoint(desktop-small) {
      display: flex;
      flex-direction: initial;
      flex-wrap: wrap;
      height: 100%;
      justify-content: flex-start;
      min-height: 40vh;
      padding: 0;

      .CardFetching {
        height: 606px;
        margin: 73px 14px 0 0;
        padding: 0;
        width: 397px;
      }
    }
  }

  @include breakpoint(desktop-small) {
    padding: 244px 134px 64px 124px;
  }
}
