@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.Favorite {
  display: flex;
  padding: 130px 0 66px;

  .favorite-view-cards {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    min-height: 80vh;
    padding: 0;
    width: 100%;

    .ContentCard {
      margin: 73px 14px 0 0;
    }

    .ContentCardMobile {
      margin-bottom: 17px;
      width: 100%;
    }

    @include breakpoint(desktop-small) {
      display: flex;
      flex-direction: initial;
      flex-wrap: wrap;
      height: 100%;
      justify-content: flex-start;
      min-height: 40vh;
      padding: 0 0 0 0;
    }
  }

  @include breakpoint(desktop-small) {
    padding: 244px 134px 64px 124px;
  }
}
