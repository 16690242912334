@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.ContentCommentList {
  h2 {
    color: black;
    font-size: pxToRem(32px);
    font-weight: 400;
    margin-bottom: 24px;
  }
}
