@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.UserContent {
  display: flex;
  flex-direction: column;
  max-width: 886px;
  padding-top: 114px;

  .ContentText {
    margin-top: 33px;
  }

  .ContentCommentList {
    display: none;
  }

  .title-content {
    color: black;
    padding: 0 23px;
    position: relative;
    z-index: 1;

    .info-user-content {
      align-items: center;
      display: flex;
      margin-bottom: 21px;
      margin-top: 40px;

      img {
        border-radius: 50%;
        height: 28px;
        text-align: center;
        width: 30px;
      }

      p {
        color: $black_dark;
        display: flex;
        font-family: Roboto, sans-serif;
        font-size: pxToRem(13px);
        font-weight: 100;
        margin: 0;
        opacity: 1;
        padding: 0 0 0 11px;

        &:nth-of-type(2) {
          font-size: pxToRem(11px);
        }
      }
    }

    h1 {
      font-size: pxToRem(20px);
      font-weight: 600;
      text-align: left;
    }

    p {
      color: #070706;
      display: none;
      font-size: pxToRem(18px);
      font-weight: 400;
      letter-spacing: 0.02px;
      margin-bottom: 65px;
      opacity: 0.6;
      padding-top: 20px;
    }
  }

  .preview-content {
    height: 145px;
  }

  @include breakpoint(desktop-small) {
    display: initial;
    padding: 121px 58px 318px 0;

    .preview-content {
      display: none;
    }

    .ContentCommentList {
      display: initial;
    }

    .title-content {
      color: black;
      padding: 0;

      h1 {
        font-size: pxToRem(36px);
        font-weight: 600;
        line-height: 51px;
        text-align: left;
      }

      p {
        display: flex;
      }

      .info-user-content {
        display: none;
      }
    }
  }
}
