@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.AdvancedSearchArea {
  align-items: flex-start;
  display: none;
  flex-direction: column;
  padding-bottom: 146px;
  padding-left: 95px;
  width: 20%;

  h2 {
    color: black;
    font-size: pxToRem(24px);
    font-weight: 600;
    padding-bottom: 10px;
    padding-left: 11px;
    text-align: left;
  }

  .CheckboxSearch {
    margin-top: 22px;
  }

  .checkbox-days {
    margin-top: 7px;
    padding-left: 14px;
  }

  .checkbox-div {
    margin-top: 60px;
    padding-left: 14px;

    h2 {
      left: -15px;
      position: relative;
      top: 6px;
    }
  }

  @include breakpoint(desktop-small) {
    display: flex;
  }
}
