@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.CommentText {
  background: #0000003b 0 0 no-repeat padding-box;
  display: flex;
  justify-content: flex-end;
  max-width: 886px;
  z-index: 10;

  .container-comment {
    background-color: $white;
    height: 100%;
    width: 100%;

    .comment-profile {
      align-items: center;
      display: flex;
      margin-top: 50px;

      img {
        border-radius: 30px;
        height: 58px;
        width: 58px;
      }

      p {
        color: $black;
        font-size: pxToRem(18px);
        font-weight: 700;
        margin-left: 14px;
        text-transform: lowercase;
      }
    }

    .comments-group {
      margin: 30px 0 23px 73px;

      article {
        align-items: center;
        background-color: #212121;
        border-radius: 13px;
        color: white;
        display: flex;
        height: 0px;
        left: 60px;
        padding: 20px;
        top: -65px;
        width: 105px;
        z-index: 10;

        p {
          font-family: $secundary_font;
          font-size: pxToRem(13px);
          font-weight: 400;
          white-space: nowrap;
        }

        span {
          border-bottom: 20px solid transparent;
          border-left: 20px solid #212121;
          border-right: 20px solid transparent;
          display: block;
          height: 3px;
          position: relative;
          right: 121px;
          top: 3px;
          transform: rotate(-134deg);
          width: 10px;
        }
      }

      form {
        margin-top: 11px;

        &:nth-child(2) {
          textarea {
            height: 181px;
          }
        }

        textarea {
          border-radius: 3px;
          border: 1px solid #D8D8D8;
          color: $black;
          font-family: $secundary_font;
          font-size: pxToRem(20px);
          height: 160px;
          max-width: 809px;
          resize: none;
          width: 100%;

          &::placeholder {
            border: none;
            color: #070706;
            font-family: $secundary_font;
            font-size: pxToRem(20px);
            font-weight: 300;
            opacity: 0.6;
            padding-left: 7px;
            padding-top: 11px;
            text-align: left;
          }

          &:focus {
            outline: none !important;
          }
        }
      }

      .container-tags {
        display: flex;
        margin: 11px 0 23px 0;
      }

      .Input {
        width: 50%;

        .error-tag {
          border: 1px solid red;
          opacity: 0.5;
        }

        .feedback-message {
          display: none;
        }

        input {
          border-radius: 3px;
          border: 1px solid #D8D8D8;
          color: $black;
          font-family: $secundary_font;
          font-size: pxToRem(20px);
          padding-left: 18px;
          resize: none;
          width: 280px;

          &::placeholder {
            font-size: pxToRem(20px);
            opacity: 0.6;
          }
        }
      }

      .Button {
        border-radius: 3px;
        box-shadow: 0 3px 6px #122F421A;
        color: $black;
        width: 396px;
      }

      .insert-tag {
        color: #707070;
        margin-top: -15px;
      }
    }

    .container-search-tags {
      border-radius: 0 10px 10px 0;
      border: 1px solid #FAFAFA;
      display: flex;
      flex-direction: column;
      margin-bottom: 23px;
    }

    .container-delete-tags {
      display: flex;
      flex-wrap: wrap;
      float: left;
      gap: 10px;
      margin-top: 20px;
      position: relative;
      width: 100%;
    }
  }
}
