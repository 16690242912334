@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";

.UserProfileInfo {
  background-color: white;
  box-shadow: 0 3px 6px #122f421a;
  display: flex;
  flex-direction: column;
  height: 79px;
  justify-content: center;
  padding-top: 104px;
  position: fixed;
  width: 100%;
  z-index: 9;

  .info-profile {
    align-items: center;
    display: flex;
    padding: 22px 0 0 130px;

    button {
      background-color: white;
      border: none;
      cursor: pointer;
      font-family: Grotesk, sans-serif;
      font-size: pxToRem(22px);
      font-weight: 700;
      margin-left: 30px;
      outline: none;
      padding: 0;
    }

    img {
      height: 85px;
      width: 85px;
      border-radius: 50px;
    }

    h1 {
      color: black;
      font-size: pxToRem(22px);
      font-weight: 400;
      margin-left: 30px;
    }
  }

  .select-info-profile {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 56px 0 4px 122px;

    .buttons-profile {
      display: flex;
      gap: 55px;
      padding-bottom: 11px;
      width: max-content;

      .NavigationLink {
        color: black;
        display: flex;
        flex-direction: column;
        font-family: $secundary_font;
        font-size: pxToRem(22px);
        height: 70px;
        outline: none;
        text-decoration: none;

        &.active {
          border-radius: 30px;
          font-weight: 700;
          height: 70px;
          box-shadow: unset;

          span {
            background-color: #0505be;
            border-radius: 30px;
            height: 6px;
            margin-top: 27px;
            width: 100%;
          }
        }
      }
    }
  }
}
