@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.PlaylistMobile {
  min-height: 80vh;

  .playlist-mobile-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 154px;

    .playlist-add-container {
      display: flex;

      .Button {
        border-radius: 28px;
        color: $black_dark;
        font-size: pxToRem(15px);
        font-weight: 500;
        height: 56px;
        margin-left: 10px;
        width: 305px;
      }
    }

    .playlist-mobile-list {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 85%;
      margin-top: 44px;
      overflow-y: scroll;
      padding-bottom: 100px;
      width: 100%;

      div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 38px;
        width: 80%;

        p {
          font-family: Grotesk, sans-serif;
          font-size: pxToRem(15px);
          font-weight: 500;
          height: auto;
          max-width: 85%;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 85%;
          -webkit-line-clamp: 2;
        }

        button {
          background-color: $white;
          background-image: url("../../../assets/images/polka-dot-button.svg");
          background-position: right;
          background-repeat: no-repeat;
          border: none;
          min-height: 29px;
          outline: none;
          width: 30px;
        }
      }

      .journeylist-fetching {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 0 0 0;
        width: 100%;

        .CardFetching {
          height: 30px;
          margin-top: 10px;
          min-width: 80%;
        }
      }
    }
  }
}
