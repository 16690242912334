@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/function.scss";
@import "../../../assets/scss/mixins.scss";
@import "../../../assets/scss/fonts.scss";

.SlideTags {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 49px 0 40px 0;

  p {
    color: $blue_magenta;
    display: none;
    font-family: $secundary_font;
    font-size: pxToRem(30px);
    margin: 0 0 0px 6%;

    &.mobile-text-slide {
      color: $light_black;
      display: block;
      font-size: pxToRem(16px);
      font-weight: 400;
      margin: 0 0 33px 0;
      padding-left: 25px;
    }

    @include breakpoint (desktop-small) {
      display: block;

      &.mobile-text-slide {
        display: none;
      }
    }
  }

  .carousel {
    align-items: center;
    border-radius: 9px;
    box-shadow: 0 0 12px #00000026;
    cursor: pointer;
    display: flex !important;
    height: 141px;
    justify-content: center;
    margin-right: 25px;
    margin-top: 20px;
    max-width: 182px;
    min-height: 141px;
    min-width: 182px;
    outline: none;
    width: 182px;

    p {
      color: $white;
      display: block;
      font-size: pxToRem(18px) !important;
      font-weight: 500;
      margin: 0 !important;
      padding: 0;
      text-align: center;
    }

    &.bgOne {
      background: transparent linear-gradient(233deg, #405EFC 0, #27D7DE 100%) 0 0 no-repeat padding-box;
    }

    &.bgTwo {
      background: transparent linear-gradient(127deg, #405EFC 0, #FF4D8E 100%) 0 0 no-repeat padding-box;
    }

    &.bgThird {
      background: transparent linear-gradient(137deg, #2BD494 0%, #405EFC 100%) 0% 0% no-repeat padding-box;
    }
  }

  .slick-slider {
    margin-left: 5.4%;
    width: 91% !important;

    .slick-prev {
      left: auto !important;
      right: 123px !important;

      span {
        padding-bottom: 7px;
        transform: rotate(180deg);
      }
    }

    .slick-next {
      padding-bottom: 2px;
      right: 57px !important;
    }

    .slick-prev:before,
    .slick-next:before {
      content: none !important;
    }

    .slick-arrow {
      align-items: center !important;
      background-color: #CFEC59 !important;
      border-radius: 50%;
      border: none;
      color: #CFEC59 !important;
      cursor: pointer !important;
      display: none !important;
      font-size: 3em;
      font-size: 4em !important;
      height: 40px !important;
      justify-content: center !important;
      position: absolute;
      top: -19px !important;
      width: 40px !important;

      span {
        color: $black;
        font-size: pxToRem(30px);
        padding-top: 2px;
      }
    }

    @include breakpoint (desktop-medium) {
      width: 92% !important;

      margin-left: 6.5%;

      .slick-arrow {
        display: flex !important;
      }
    }
  }
}
