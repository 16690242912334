@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/function";

.EditFavoriteModal {
  align-items: center;
  background: #00000099 0 0 no-repeat padding-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  overflow: scroll;
  position: fixed;
  top: 0;
  transition: visibility 0s, opacity 0.5s ease-in-out;
  width: 100%;
  z-index: 15;

  button {
    background: #212121 0 0 no-repeat padding-box;
    border: none;
    border-radius: 13px;
    color: $white;
    font-family: Grotesk, sans-serif;
    font-size: pxToRem(15px);
    height: 56px;
    outline: none;
    width: 80%;

    &:nth-child(1) {
      margin-bottom: 18px;
    }

    &:nth-child(2) {
      background: $light_green no-repeat padding-box;
      color: $black_dark;
      font-weight: 600;
      margin-bottom: 70px;
    }
  }
}
