@import "../../../assets/scss/mixins";

.Loader{
  align-items: center;
  background-color: white;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 10;

  .loading {
    position: absolute;
    transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    -webkit-transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  }

  .loading > div {
    background-color: black;
    border-radius: 50%;
    height: 15px;
    margin-left: -5px;
    margin-top: -5px;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    width: 15px;
    -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  }

  .loading > div:nth-child(1) {
    left: -22px;
    position: absolute;
    top: -22px;
  }

  .loading > div:nth-child(2) {
    left: -22px;
    position: absolute;
    top: 22px;
  }

  .loading > div:nth-child(3) {
    left: 22px;
    position: absolute;
    top: -22px;
  }

  .loading > div:nth-child(4) {
    left: 22px;
    position: absolute;
    top: 22px;
  }

  .loading  > div:nth-child(1) {
    animation: identityWorkingGreen 1.8s ease infinite;
    -webkit-animation: identityWorkingGreen 1.4s ease infinite;
  }

  .loading > div:nth-child(2) {
    animation: identityWorkingYellow 1.8s ease infinite;
    -webkit-animation: identityWorkingYellow 1.4s ease infinite;
  }

  .loading > div:nth-child(3) {
    animation: identityWorkingRed 1.8s ease infinite;
    -webkit-animation: identityWorkingRed 1.4s ease infinite;
  }

  .loading > div:nth-child(4) {
    animation: identityWorkingBlue 1.8s ease infinite;
    -webkit-animation: identityWorkingBlue 1.4s ease infinite;
  }

  @-moz-keyframes identityWorkingGreen {
    0%, 100% {
      left: -22px;
      position: absolute;
      top: -22px;
    }
    25% {
      left: 22px;
      position: absolute;
      top: -22px;
    }
    50% {
      left: 22px;
      position: absolute;
      top: 22px;
    }
    75% {
      left: -22px;
      position: absolute;
      top: 22px;
    }
    100% {
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  @-webkit-keyframes identityWorkingGreen {
    0%, 100% {
      left: -22px;
      position: absolute;
      top: -22px;
    }
    25% {
      left: 22px;
      position: absolute;
      top: -22px;
    }
    50% {
      left: 22px;
      position: absolute;
      top: 22px;
    }
    75% {
      left: -22px;
      position: absolute;
      top: 22px;
    }
    100% {
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  @-o-keyframes identityWorkingGreen {
    0%, 100% {
      left: -22px;
      position: absolute;
      top: -22px;
    }
    25% {
      left: 22px;
      position: absolute;
      top: -22px;
    }
    50% {
      left: 22px;
      position: absolute;
      top: 22px;
    }
    75% {
      left: -22px;
      position: absolute;
      top: 22px;
    }
    100% {
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  @keyframes identityWorkingGreen {
    0%, 100% {
      left: -22px;
      position: absolute;
      top: -22px;
    }
    25% {
      left: 22px;
      position: absolute;
      top: -22px;
    }
    50% {
      left: 22px;
      position: absolute;
      top: 22px;
    }
    75% {
      left: -22px;
      position: absolute;
      top: 22px;
    }
    100% {
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  @-moz-keyframes identityWorkingYellow {
    0%, 100% {
      left: -22px;
      position: absolute;
      top: 22px;
    }
    25% {
      left: -22px;
      position: absolute;
      top: -22px;
    }
    50% {
      left: 22px;
      position: absolute;
      top: -22px;
    }
    75% {
      left: 22px;
      position: absolute;
      top: 22px;
    }
    100% {
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  @-webkit-keyframes identityWorkingYellow {
    0%, 100% {
      left: -22px;
      position: absolute;
      top: 22px;
    }
    25% {
      left: -22px;
      position: absolute;
      top: -22px;
    }
    50% {
      left: 22px;
      position: absolute;
      top: -22px;
    }
    75% {
      left: 22px;
      position: absolute;
      top: 22px;
    }
    100% {
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  @-o-keyframes identityWorkingYellow {
    0%, 100% {
      left: -22px;
      position: absolute;
      top: 22px;
    }
    25% {
      left: -22px;
      position: absolute;
      top: -22px;
    }
    50% {
      left: 22px;
      position: absolute;
      top: -22px;
    }
    75% {
      left: 22px;
      position: absolute;
      top: 22px;
    }
    100% {
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  @keyframes identityWorkingYellow {
    0%, 100% {
      left: -22px;
      position: absolute;
      top: 22px;
    }
    25% {
      left: -22px;
      position: absolute;
      top: -22px;
    }
    50% {
      left: 22px;
      position: absolute;
      top: -22px;
    }
    75% {
      left: 22px;
      position: absolute;
      top: 22px;
    }
    100% {
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  @-moz-keyframes identityWorkingRed {
    0%, 100% {
      left: 22px;
      position: absolute;
      top: -22px;
    }
    25% {
      left: 22px;
      position: absolute;
      top: 22px;
    }
    50% {
      left: -22px;
      position: absolute;
      top: 22px;
    }
    75% {
      left: -22px;
      position: absolute;
      top: -22px;
    }
    100% {
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  @-webkit-keyframes identityWorkingRed {
    0%, 100% {
      left: 22px;
      position: absolute;
      top: -22px;
    }
    25% {
      left: 22px;
      position: absolute;
      top: 22px;
    }
    50% {
      left: -22px;
      position: absolute;
      top: 22px;
    }
    75% {
      left: -22px;
      position: absolute;
      top: -22px;
    }
    100% {
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  @-o-keyframes identityWorkingRed {
    0%, 100% {
      left: 22px;
      position: absolute;
      top: -22px;
    }
    25% {
      left: 22px;
      position: absolute;
      top: 22px;
    }
    50% {
      left: -22px;
      position: absolute;
      top: 22px;
    }
    75% {
      left: -22px;
      position: absolute;
      top: -22px;
    }
  }

  @keyframes identityWorkingRed {
    0%, 100% {
      left: 22px;
      position: absolute;
      top: -22px;
    }
    25% {
      left: 22px;
      position: absolute;
      top: 22px;
    }
    50% {
      left: -22px;
      position: absolute;
      top: 22px;
    }
    75% {
      left: -22px;
      position: absolute;
      top: -22px;
    }
    100% {
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  @-moz-keyframes identityWorkingBlue {
    0%, 100% {
      left: 22px;
      position: absolute;
      top: 22px;
    }
    25% {
      left: -22px;
      position: absolute;
      top: 22px;
    }
    50% {
      left: -22px;
      position: absolute;
      top: -22px;
    }
    75% {
      left: 22px;
      position: absolute;
      top: -22px;
    }
    100% {
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  @-webkit-keyframes identityWorkingBlue {
    0%, 100% {
      left: 22px;
      position: absolute;
      top: 22px;
    }
    25% {
      left: -22px;
      position: absolute;
      top: 22px;
    }
    50% {
      left: -22px;
      position: absolute;
      top: -22px;
    }
    75% {
      left: 22px;
      position: absolute;
      top: -22px;
    }
    100% {
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  @-o-keyframes identityWorkingBlue {
    0%, 100% {
      left: 22px;
      position: absolute;
      top: 22px;
    }
    25% {
      left: -22px;
      position: absolute;
      top: 22px;
    }
    50% {
      left: -22px;
      position: absolute;
      top: -22px;
    }
    75% {
      left: 22px;
      position: absolute;
      top: -22px;
    }
    100% {
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  @keyframes identityWorkingBlue {
    0%, 100% {
      left: 22px;
      position: absolute;
      top: 22px;
    }
    25% {
      left: -22px;
      position: absolute;
      top: 22px;
    }
    50% {
      left: -22px;
      position: absolute;
      top: -22px;
    }
    75% {
      left: 22px;
      position: absolute;
      top: -22px;
    }
    100% {
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  .bottom-loader {
    background: #000000 0 0 no-repeat padding-box;
    filter: blur(2px);
    height: 9px;
    opacity: 0.04;
    position: relative;
    top: 79px;
    width: 305px;
  }

  @include breakpoint(desktop-small) {
    display: none;
  }
}
