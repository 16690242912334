@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/fonts";

.footer {
  align-items: center;
  background-color: $blue_magenta;
  display: flex;
  height: 168px;
  justify-content: center;
  width: 100%;

  .footer-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 75%;

    .footer-left {
      display: flex;
      justify-content: left;
      margin-bottom: 25px;
      width: 33%;

      .social-media {
        border-color: transparent;
        color: $white;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        stroke: none;
        width: 143px;

        svg {
          height: 15px;
          width: 19px;
        }
      }
    }

    .footer-center {
      color: $white;
      cursor: pointer;
      display: none;
      font-size: pxToRem(36px);
      font-weight: 500;
      justify-content: center;
      width: 33%;
    }

    .footer-right {
      p {
        color: $white;
        cursor: pointer;
        font-size: pxToRem(10px);
        font-weight: 400;
        text-align: right;
        width: 143px;
      }
    }
  }

  @include breakpoint(desktop-small){
    background-color: $strong_blue;
    height: 387px;

    .footer-container {
      flex-direction: row;

      .footer-left {
        margin-bottom: 0;

        .social-media {
          width: 168px;

          svg {
            height: 26px;
            width: 32px;
          }
        }
      }

      .footer-center {
        display: flex;
        justify-content: flex-start;
      }

      .footer-right {
        p {
          font-size: pxToRem(20px);
          width: 100%;
        }
      }
    }
  }
}
