@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/function";

.EditProfile {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 90px;
  width: 100%;

  .section-header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .ExitButton {
      margin-bottom: -100px;
      margin-right: 30px;
    }
  }

  h2 {
    display: none;
  }

  .image-photographic {
    background: url("../../../../assets/images/photographic-camera.svg");
    background-size: contain;
    height: 30px;
    left: 74px;
    position: relative;
    top: 37px;
    width: 31px;
  }

  button:not(.ExitButton, .BluePopUp button) {
    background-color: white;
    background-size: 117px 116px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: 116px;
    margin-left: -6px;
    margin-top: 26px;
    outline: none;
    width: 117px;

    &:nth-child(4) {
      background: $light_green 0% 0% no-repeat padding-box;
      border-radius: 33px;
      box-shadow: 0px 3px 6px #122F421A;
      color: $black_dark;
      font-family: Grotesk, sans-serif;
      font-size: pxToRem(18px);
      font-weight: 500;
      height: 37px;
      margin-top: 50px;
      width: 204px;
    }

    @include breakpoint(desktop-small) {
      &:nth-child(4) {
        align-items: center;
        background: $pink_button_mobile 0% 0% no-repeat padding-box;
        border-radius: 3px;
        color: $white;
        display: flex;
        font-weight: 700;
        height: 43px;
        justify-content: center;
        margin: 42px 0 0 0;
        width: 110px;
      }
    }
  }

  form {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    width: 100%;

    .Input {
      align-items: center;
      display: flex;
      width: 100%;

      div {
        align-items: center;
        display: flex;
        width: 100%;

        &:nth-child(2), &:nth-child(3) {
          margin-top: 24px;

          label {
            text-align: start;
            width: 80%;
          }
        }

        label {
          font-family: Grotesk, sans-serif;
          font-size: pxToRem(13px);
          font-weight: 700;
          text-align: start;
          width: 80%;
        }

        input {
          border: none;
          border-bottom: 1px solid #7373737a;
          border-radius: 0;
          padding: 0;
          margin: -1px;
          width: 80%;

          &::placeholder {
            color: rgba(41, 41, 41, 0.57);
          }
        }
      }
    }

    @include breakpoint(desktop-small) {
      align-items: initial;
      margin-top: 23px;
      width: auto;

      .Input {
        align-items: initial;

        div {
          align-items: flex-start;

          label {
            font-size: pxToRem(18px);
            margin-bottom: 10px;
          }

          input {
            border: 1px solid $black_dark;
            border-radius: 3px;
            padding-left: 10px;
            width: 385px;
          }
        }
      }
    }
  }

  .fetching-update {
    align-items: center;
    background: $light_green 0% 0% no-repeat padding-box;
    border-radius: 33px;
    box-shadow: 0 3px 6px #122F421A;
    color: white;
    display: flex;
    font-family: Grotesk, sans-serif;
    font-size: pxToRem(18px);
    font-weight: 700;
    height: 37px;
    justify-content: center;
    margin: 50px 0 0 0;
    width: 204px;

    div {
      animation: loader 1.4s infinite linear;
      background: $white;
      background: linear-gradient(to right, $white 10%, rgba(255, 255, 255, 0) 42%);
      background: -moz-linear-gradient(left, $white 10%, rgba(255, 255, 255, 0) 42%);
      background: -ms-linear-gradient(left, $white 10%, rgba(255, 255, 255, 0) 42%);
      background: -o-linear-gradient(left, $white 10%, rgba(255, 255, 255, 0) 42%);
      background: -webkit-linear-gradient(left, $white 10%, rgba(255, 255, 255, 0) 42%);
      border-radius: 50%;
      font-size: 10px;
      height: 3em;
      position: relative;
      text-indent: -9em;
      transform: translateZ(0);
      width: 3em;
      -ms-transform: translateZ(0);
      -webkit-animation: loader 1s infinite linear;
      -webkit-transform: translateZ(0);
    }

    div:before {
      background: $white;
      border-radius: 100% 0 0 0;
      content: '';
      height: 50%;
      left: 0;
      position: absolute;
      top: 0;
      width: 50%;
    }

    div:after {
      background: $light_green;
      border-radius: 50%;
      bottom: 0;
      content: '';
      height: 75%;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      width: 75%;
    }

    @-webkit-keyframes loader {
      0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes loader {
      0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
      }
    }

    @include breakpoint(desktop-small) {
      background: $pink_button_mobile 0% 0% no-repeat padding-box;
      border-radius: 3px;
      height: 43px;
      justify-content: center;
      margin: 42px 0 0 0;
      width: 110px;

      div:after {
        background: $pink_button_mobile;
      }
    }
  }

  @include breakpoint(desktop-small) {
    align-items: flex-start;
    padding-top: 0;

    h2 {
      display: flex;
    }
  }
}
