@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/function.scss";
@import "../../../assets/scss/mixins.scss";
@import "../../../assets/scss/fonts.scss";

.SlideCards {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 49px 0 40px 0;

  .text-slide-cards {
    color: $blue_magenta;
    font-family: $secundary_font;
    font-size: pxToRem(30px);
    margin: 0 0 0px 6%;

    &.mobile-text-slide {
      color: $light_black;
      font-size: pxToRem(16px);
      font-weight: 400;
      margin: 0 0 33px 0;
      padding-left: 25px;
    }
  }

  .slick-slider {
    margin-left: 5.0%;
    width: 91% !important;
    
    .CardSlide {
      margin: 40px 30px 10px 4px;
      scroll-snap-align: center;
    }

    .fetching {
      animation: shimmerAnim 2s infinite linear;
      background-image: linear-gradient(to right, #eeeeee 40%, #dddddd 50%, #eeeeee 60%);
      border-radius: 9px;
      box-shadow: 0 0 12px #0000001C;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      height: 358px !important;
      justify-content: space-between;
      margin-right: 35px;
      margin-top: 40px;
      width: 302px !important;

      @keyframes shimmerAnim {
        0% {
          background-position: -868px 0;
        }

        100% {
          background-position: 868px 0;
        }
      }
  }

    .slick-prev {
      left: auto !important;
      right: 123px !important;

      span {
        padding-bottom: 7px;
        transform: rotate(180deg);
      }
    }

    .slick-next {
      padding-bottom: 2px;
      right: 57px !important;
    }

    .slick-prev:before,
    .slick-next:before {
      content: none !important;
    }

    .slick-arrow {
      align-items: center !important;
      background-color: #CFEC59 !important;
      border-radius: 50%;
      border: none;
      color: #CFEC59 !important;
      cursor: pointer !important;
      display: none !important;
      font-size: 4em !important;
      height: 40px !important;
      justify-content: center !important;
      position: absolute;
      top: -19px !important;
      width: 40px !important;

      span {
        color: $black;
        font-size: pxToRem(30px);
        padding-top: 2px;
      }
    }

    @include breakpoint (desktop-medium) {
      margin-left: 6.5%;
      width: 92% !important;

      .slick-arrow {
        display: flex !important;
      }
    }
  }
}
