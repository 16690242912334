@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/function";
@import "../../../../assets/scss/fonts.scss";

.OptionsDiversityAspects {
  width: 75%;

  .diversityContainer {
    flex-direction: column;

    p {
      font-family: $primary_font_book;
      font-size: pxToRem(18px);
      font-weight: 600;
      margin-bottom: 13px;
    }

    .diversityCheckbox {
      align-items: flex-start;
      display: flex;
      height: 50px;
      justify-content: space-between;
      width: 70%;

      .Checkbox {
        padding-left: 30px;
      }
    }

    .list-diverysity {
      display: flex;
      flex-wrap: wrap;
      gap: 17px 28px;
      width: 72%;

      .Checkbox {
        margin-bottom: 20px;
        padding-left: 30px;
      }
    }

    .diversityOthersCheckbox {
      display: flex;
      justify-content: space-between;
      width: 350px;

      .Input {
        margin-top: 0;

        label {
          margin: 0;
        }
        
        input {
          background-color: white;
          border: 3px solid black;
          font-family: $secundary_font;
          height: 45px;
          width: 280px;

          &::placeholder {
            font-family: $primary_font_book;
            font-size: pxToRem($reading_size);
            padding-left: 10px;
          }
        }
      }

      .Button {
        align-items: center;
        background-color: #0505BE;
        border-radius: 5px;
        border-style: none;
        color: white;
        cursor: pointer;
        display: flex;
        font-family: $primary_font_book;
        font-size: pxToRem(35px);
        font-weight: 400;
        height: 53px;
        justify-content: center;
        margin-left: 10px;
        width: 40px;
      }
    }
  }
}
