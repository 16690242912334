@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/fonts";

.LoaderButton {
  align-items: center;
  background-color: #CFEC59;
  contain: size;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-top: 29px;
  outline: transparent;
  width: 399px;

  div {
    animation: loader 1.4s infinite linear;
    background: #000000;
    background: linear-gradient(to right, #000000 10%, rgba(255, 255, 255, 0) 42%);
    background: -moz-linear-gradient(left, #120c0c 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #000000 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #040202 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #1a1010 10%, rgba(255, 255, 255, 0) 42%);
    border-radius: 50%;
    font-size: 10px;
    height: 3em;
    position: relative;
    text-indent: -9em;
    transform: translateZ(0);
    width: 3em;
    -ms-transform: translateZ(0);
    -webkit-animation: loader 1.4s infinite linear;
    -webkit-transform: translateZ(0);
  }

  div:before {
    background: #000000;
    border-radius: 100% 0 0 0;
    content: '';
    height: 50%;
    left: 0;
    position: absolute;
    top: 0;
    width: 50%;
  }

  div:after {
    background: #CFEC59;
    border-radius: 50%;
    bottom: 0;
    content: '';
    height: 75%;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 75%;
  }

  @-webkit-keyframes loader {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
