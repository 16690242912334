@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/function";

.Tag {
  align-items: center;
  background: unset;
  border-radius: 30px;
  border: 2px solid $black;
  color: $black;
  cursor: pointer;
  display: flex;
  font-family: $primary_font_book;
  font-size: pxToRem(15px);
  height: 40px;
  justify-content: center;
  margin: 0;
  min-width: 150px;
  outline: none;
  padding: 0 12px;
  width: fit-content;

  &.passed:hover {
    background-color: rgba(176, 224, 98, 0.575);
    opacity: 1;
  }

  &.active {
    background: rgba(176, 224, 98, 0.575);
  }

  &.invertColors {
    filter: invert(2);
  }
}
