@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/fonts";

.TagFollow {
  align-items: center;
  background-color: $white;
  border: 2px solid $black_dark;
  border-radius: 30px;
  color: $black_dark;
  cursor: pointer;
  display: flex;
  font-family: 'Grotesk', sans-serif;
  font-size: pxToRem(15px);
  height: 39px;
  justify-content: center;
  margin: 0;
  outline: none;
  padding: 0 17px 0 17px;
  transition: border 0.8s ease;
  width: auto;

  &:hover {
    opacity: 1;
  }

  &.active {
    background-color: $light_green;
    border: 2px solid $light_green;
    transition: border 0.5s ease;
  }

  &.invertColors {
    filter: invert(2);
  }
}
