@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/fonts";

.ContentCard {
  background: $black;
  border-radius: 10px;
  display: none;
  flex-direction: column;
  height: 606px;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  width: 397px;

  .image-content {
    -webkit-transition: -webkit-transform 0.5s ease;
    height: 100%;
    position: absolute;
    transition: transform 0.5s ease;
    width: 100%;

    &.show {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }

  .bg-opacity {
    background-image: linear-gradient(
      to bottom,
      rgba(2, 1, 1, 0.212),
      transparent
    );
    border-radius: 10px;
    height: 520px;
    width: 397px;
  }

  .card-type {
    align-self: center;
    display: flex;
    height: 40px;
    justify-content: space-between;
    margin-top: 23px;
    padding-left: 17px;
    position: relative;
    width: 320px;

    .delete-card {
      background-color: unset;
      background-image: url("../../../assets/images/delete.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 50px;
      border: none;
      cursor: pointer;
      height: 70px;
      left: 84% !important;
      outline: none;
      padding: 0;
      position: absolute;
      top: -12px;
      width: 70px;
      z-index: 8;
    }
  }

  .card-infos {
    background-image: linear-gradient(
      to bottom,
      transparent,
      rgba(0, 0, 0, 0.74)
    );
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    padding: 0 40px 23px 10px;
    position: relative;
    box-sizing: border-box;

    @include breakpoint(desktop-large) {
      padding: 0 0 23px 10px;
    }

    .platform-card {
      margin-bottom: 88px;
      max-width: 325px;
      padding: 10px 11px 0 0;
      box-sizing: border-box;

      @include breakpoint(desktop-large) {
        margin-bottom: 16px;
        max-width: 397px;
      }

      p {
        color: $white;
        font-size: pxToRem(16px);
        font-weight: 400;
        margin-left: 0;
        opacity: 0.8;
      }

      &.show {
        margin-top: 0;
        transform: translateY(-20px);
        transition: transform 0.3s linear;
      }
    }

    .title-card {
      font-weight: 400;
      gap: 4px;
      text-align: left;
      width: 340px;

      p {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        color: $white;
        display: -webkit-box;
        font-family: $primary_font_book;
        font-size: pxToRem(24px);
        font-weight: 600;
        margin-bottom: 3px;
        margin-left: 0;
        max-width: 325px;
        opacity: 1;
        overflow: hidden;
        text-overflow: ellipsis;

        @include breakpoint(desktop-large) {
          font-size: pxToRem(32px);
          max-width: 397px;
        }
      }
    }

    .tags-card {
      align-items: flex-end;
      border-top: 1px solid $white_background_tag;
      display: flex;
      gap: 0 8px;
      height: 50px;
      overflow: hidden;
      width: 100%;

      .Tag {
        background: $light_green;
        border-radius: 18px;
        border: none;
        color: $black;
        display: block;
        font-size: pxToRem(14px);
        font-weight: 600;
        height: 28px;
        min-width: 175px;
        opacity: 0.9;
        overflow: hidden;
        padding: 0 12px;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: fit-content;
      }

      .button-card {
        background: $light_green;
        border-radius: 18px;
        border: none;
        color: $black;
        cursor: pointer;
        font-size: pxToRem(16px);
        font-weight: 600;
        height: 28px;
        opacity: 0.7;
        outline: none;
        width: 28px;
      }
    }

    .about-content-card {
      opacity: 0;
      pointer-events: none;
      position: fixed;
      padding: 0 10px 0 0;
      box-sizing: border-box;

      @include breakpoint(desktop-large) {
        padding: 0;
      }

      &.show {
        opacity: 1;
        pointer-events: visible;
        position: initial;
        transition: opacity 0.6s linear;
      }

      .text-about {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        max-width: 325px;
        padding: 0 11px 0 0;
        box-sizing: border-box;

        @include breakpoint(desktop-large) {
          padding: 0;
          max-width: 397px;
        }

        a {
          border-bottom: 1px solid $white;
          color: $white;
          cursor: pointer;
          font-weight: 700;
          overflow: visible;
        }

        p {
          -webkit-box-orient: vertical;
          color: #ffffffc7;
          line-height: 16px;
          margin: 0;
          max-height: 62px;
          opacity: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .buttons-about {
        display: flex;
        flex-direction: column;
        gap: 11px 0;
        margin: 18px 0 0 0;

        button {
          align-items: center;
          background-color: $pink_button_mobile;
          border-radius: 29px;
          border: none;
          color: $white;
          cursor: pointer;
          display: flex;
          font-family: $secundary_font;
          font-size: pxToRem(19px);
          height: 57px;
          outline: none;
          text-align: left;
          width: 100%;

          img {
            height: 36px;
            margin: 0 8px 0 30px;
            width: 36px;
          }
        }

        .button-blue {
          background-color: #1212c1;
        }
      }
    }
  }

  @include breakpoint(desktop-small) {
    display: flex;
    height: 490px;
    width: 325px;

    .card-type {
      width: 250px;
    }
  }

  @include breakpoint(desktop-large) {
    height: 606px;
    width: 397px;

    .card-type {
      width: 360px;
    }

    .card-infos {
      box-sizing: unset;
    }
  }
}
