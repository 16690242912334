@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/fonts";

.SignUp {
  align-items: center;
  display: flex;
  flex-direction: column;

  .FacebookSignIn {
    button {
      background: white url("../../../assets/images/facebook-square-brands.svg") no-repeat 15%;
      border: 1px solid #DCDCDC;
      border-radius: 25px;
      box-shadow: 0 3px 6px white;
      color: $black_dark;
      font-family: Grotesk, sans-serif;
      font-size: pxToRem(15px);
      font-weight: 500;
      height: 56px;
      letter-spacing: 0;
      margin-left: -3px;
      margin-top: 11px;
      padding-left: 68px;
      width: 300px;

      @include breakpoint(desktop-small) {
        background: #385185 url("../../../assets/images/facebook.png") no-repeat 20%;
        box-shadow: 0 3px 6px #122f421a;
        color: white;
        font-size: pxToRem(20px);
        font-weight: 400;
        height: 48px;
        margin-left: -3px;
        padding: 0;
        width: 400px;
      }
    }
  }

  .alert-password {
    padding: 20px 0;
    text-align: justify;
    width: 90%;

    @include breakpoint(desktop-small) {
      text-align: left;
      width: 390px;
    }
  }

  &.fetching-signup {
    .Input {
      opacity: 0.5;
    }
  }

  .screen-splitter {
    align-items: center;
    display: flex;
    gap: 6px;
    height: 14px;
    margin: 18px 0 -4px 0;

    span {
      background-color: rgba(212, 208, 208, 0.87);
      height: 2px;
      opacity: 0.5;
      padding: 0;
      transition: transform 0.3s ease;
      width: 122px;
    }

    p {
      background-color: white;
      color: black;
      font-size: pxToRem(16px);
      font-weight: 700;
      margin-left: 10px;
      text-transform: uppercase;
      width: 35px;
    }

    @include breakpoint(desktop-small) {
      height: 100px;
      margin-bottom: 0;

      span {
        background-color: $black_dark;
        height: 1px;
        width: 175px;
      }
    }
  }

  form {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    .Input {
      display: flex;
      flex-direction: column;
      width: 300px;

      p {
        height: 20px;
        margin: 0;
      }

      svg {
        bottom: 50%;
      }

      input {
        background-color: $white_smoke;
        border: none;
        border-radius: 13px;
        height: 56px;
        width: 290px;
      }

      &:nth-of-type(1) {
        margin-top: 30px;
      } 

      &:nth-of-type(3) {
        display: none;
      }

      &:nth-of-type(6) {
        display: none;
      }

      @include breakpoint(desktop-small) {
        border-radius: 0;
        width: 399px;

        input {
          background-color: $white;
          border: 1px solid $erie_black;
          border-radius: 0;
          height: 48px;
          width: 387px;
        }

        &:nth-of-type(1) {
          margin-top: 0;
        } 

        &:nth-of-type(3) {
          display: flex;
        }

        &:nth-of-type(4) {
          display: flex;
        }

        &:nth-of-type(6) {
          display: flex;
        }
      }
    }

    .signup-button  {
      border: none;
      border-radius: 13px;
      color: $black;
      display: none;
      font-family: Grotesk, sans-serif;
      font-size: pxToRem(17px);
      font-weight: 500;
      height: 56px;
      margin-top: 75px;
      outline: none;
      width: 300px;

      @include breakpoint(desktop-small){
        align-items: center;
        border: none;
        border-radius: 0;
        box-shadow: 0 3px 6px #122F421A;
        color: $black_dark;
        cursor: pointer;
        display: flex;
        font-family: Grotesk, sans-serif;
        font-size: pxToRem(20px);
        font-weight: 700;
        height: 48px;
        justify-content: center;
        margin: 29px 0 0 -1px;
        outline: transparent;
        padding: 10px 30px;
        width: 399px;
      }
    }

    .signup-button-mobile {
      border: none;
      border-radius: 13px;
      color: $black;
      font-family: Grotesk, sans-serif;
      font-size: pxToRem(17px);
      font-weight: 500;
      height: 56px;
      margin-top: 75px;
      outline: none;
      width: 300px;

      @include breakpoint(desktop-small) {
        display: none;
      }
    }
  }

  .login-registration {
    align-items: center;
    color: $black_dark;
    display: flex;
    justify-content: flex-start;
    margin-top: 19px;
    position: relative;
    top: -147px;
    width: 300px;

    p {
      color: #b5b4b4;
      font-size: pxToRem(13px);
      font-weight: 500;
      justify-content: flex-start;
      padding: 5px 0 0 13px;
      position: relative;
      width: auto;
    }

    .Button {
      background: none;
      border-radius: 0;
      box-shadow: none;
      color: $black_dark;
      font-size: pxToRem(13px);
      font-weight: 500;
      height: 0;
      margin: 0 0 10px 5px;
      padding: 0;
      width: auto;
    }

    @include breakpoint(desktop-small) {
      top: -9px;
      width: 350px;

      p {
        color: $black_dark;
        font-size: pxToRem(20px);
        font-weight: 700;
      }

      .Button {
        background: none;
        border-radius: 0;
        box-shadow: none;
        color: $black_dark;
        font-size: pxToRem(20px);
        font-weight: 700;
        height: 0px;
        letter-spacing: 0.02px;
        margin: -24px 1px 1px 8px;
        padding: 0;
        text-decoration: underline;
        width: 136px;
      }
    }
  }

  @include breakpoint(desktop-small){
    background-color: $white;
    border-radius: 5px;
    height: 787px;
    margin-top: 10%;
    padding-top: 50px;
    width: 630px;

    .exit-signup-container {
      align-items: flex-start;
      display: flex;
      height: 0;
      justify-content: flex-end;
      padding-bottom: -50px;
      width: 100%;

      .exit-entry {
        position: relative !important;
        top: -37px !important;
      }
    }
  }
}
