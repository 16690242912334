@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/function";
@import "../../../../assets/scss/fonts";

.FrontCover {
  h2 {
    font-family: $secundary_font;
    font-size: pxToRem(20px);
    font-weight: 700;
  }

  .front-cover-container {
    align-items: center;
    background-color: #F9F9F9;
    display: flex;
    flex-direction: column;
    height: 638px;
    justify-content: center;
    margin-top: 10px;
    max-width: 685px;
    width: 90%;

    .container-photo {
      align-items: center;
      background-color: white;
      border: 1px solid #707070;
      display: flex;
      flex-direction: column;
      height: 372px;
      justify-content: center;
      width: 80%;

      p {
        color: $black_dark;
        font-family: $secundary_font;
        font-size: pxToRem(20px);
        font-weight: 700;
        text-align: center;
        width: 346px;
      }

      input[type="file"] {
        display: none;
      }

      label {
        align-items: center;
        background-color: $light_green;
        border-radius: 25px;
        border: unset;
        cursor: pointer;
        display: flex;
        font-family: $secundary_font;
        font-size: pxToRem(18px);
        font-weight: 700;
        height: 44px;
        justify-content: space-evenly;
        margin-top: 35px;
        outline: none;
        width: 237px;
      }

      .image-cover {
        height: 100%;
        width: 100%;
      }
    }

    .re-upload {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-top: 50px;
    }

    p {
      color: $black_dark;
      font-family: $secundary_font;
      font-size: pxToRem(20px);
      font-weight: 700;
      text-align: center;
      width: 346px;
    }

    input[type="file"] {
      display: none;
    }

    label {
      align-items: center;
      background-color: $light_green;
      border-radius: 25px;
      border: unset;
      cursor: pointer;
      display: flex;
      font-family: $secundary_font;
      font-size: pxToRem(18px);
      font-weight: 700;
      height: 44px;
      justify-content: space-evenly;
      margin-top: 35px;
      outline: none;
      width: 237px;
    }
  }

  .cover-extension {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 90px;
    width: 90%;

    h3 {
      color: $blue_magenta;
      font-family: $secundary_font;
      font-size: pxToRem(30px);
    }

    p {
      color: $black_dark;
      font-family: $secundary_font;
      font-size: pxToRem(17px);
      margin-top: 5px;
    }

    a {
      align-items: center;
      background-color: $light_green;
      border-radius: 25px;
      border: unset;
      color: $black_dark;
      cursor: pointer;
      display: flex;
      font-family: $secundary_font;
      font-size: pxToRem(18px);
      font-weight: 700;
      height: 46px;
      justify-content: space-evenly;
      margin-top: 35px;
      outline: none;
      text-decoration: unset;
      width: 186px;

      img {
        transform: rotate(180deg);
      }
    }
  }
}
