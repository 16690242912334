@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/function.scss";
@import "../../../assets/scss/mixins.scss";
@import "../../../assets/scss/fonts.scss";

.navbar {
  align-items: center;
  background-color: $strong_blue;
  display: none;
  height: 96px;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  .navbar-container {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &.mobile {
      width: 100%;
      padding: 0 5%;
      box-sizing: border-box;
    }

    @include breakpoint(desktop-large) {
      width: 87%;
    }

    .navbar-left {
      align-items: center;
      cursor: pointer;
      display: flex;

      .navbar-menu {
        display: flex;
        flex-direction: column;
        height: 16px;
        justify-content: space-between;
        margin-right: 23px;
        width: 20px;

        span {
          background-color: $white;
          height: 3px;
          width: 19px;
        }

        @include breakpoint(desktop-medium) {
          display: none;
        }
      }

      h1 {
        color: $white;
        font-size: pxToRem(30px);
        font-weight: 500;
        letter-spacing: 0.8px;
      }

      .navbar-menu-bar {
        cursor: initial;
        display: none;
        justify-content: space-between;
        margin-left: 22px;
        margin-top: 6px;
        width: 20rem;

        .library-container {
          .library-button {
            background-color: unset;
            border: none;
            color: $white;
            cursor: pointer;
            font-family: $primary_font_book;
            margin: 0;
            outline: none;
            padding: 0 0 7px 0;
            text-decoration: inherit;

            &.active,
            &:hover {
              box-shadow: 0 4px 0 -2px $white;
            }
          }

          .library-menu-info {
            align-items: flex-start;
            background-color: $white;
            border-radius: 20px;
            border: 2px solid $blue_magenta;
            display: flex;
            flex-direction: column;
            height: 201px;
            justify-content: space-around;
            margin-left: -25px;
            opacity: 0;
            padding: 26px 27px;
            pointer-events: none;
            position: fixed;
            width: 150px;

            button {
              background: transparent;
              border: none;
              color: $blue_magenta;
              cursor: pointer;
              font-family: $primary_font_book;
              font-size: pxToRem(17px);
              font-weight: 600;
              outline: none;
              text-align: left;

              &:hover {
                box-shadow: 0 4px 0 -2px $blue_magenta;
              }
            }

            &.show {
              opacity: 1;
              pointer-events: visible;
              transition: opacity 0.2s linear;
            }
          }
        }

        a {
          border: none;
          color: $white;
          cursor: pointer;
          font-family: $primary_font_book;
          text-decoration: inherit;
        }

        a:link {
          color: $white;
          cursor: auto;
          cursor: pointer;
          outline: 0;
          text-decoration: inherit;
        }

        a:visited {
          color: $white;
          cursor: auto;
          cursor: pointer;
          outline: 0;
          text-decoration: inherit;
        }

        @include breakpoint(desktop-small) {
          margin-left: 43px;
          width: 28rem;

          a,
          .library-button {
            font-size: pxToRem(18px);
          }
        }

        @include breakpoint(desktop-medium) {
          display: flex;
          margin-left: 62px;
          margin-right: 40px;
          width: 31rem;

          a,
          .library-button {
            font-size: pxToRem(20px);
          }
        }

        @include breakpoint(desktop-large) {
          width: 35rem;
        }
      }
    }

    .navbar-menu-info {
      align-items: flex-start;
      background-color: $white;
      display: flex;
      flex-direction: column;
      height: 170px;
      justify-content: space-around;
      margin-top: 250px;
      padding: 5px 30px;
      position: fixed;
      width: 280px;

      button {
        background: transparent;
        border: none;
        cursor: pointer;
        font-family: $primary_font_book;
        font-size: pxToRem(19px);
        text-align: left;
      }
    }

    .navbar-right {
      align-items: center;
      background-color: $white;
      border-radius: 21px;
      border: transparent;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      height: 41px;
      margin-right: 40px;
      padding-left: 6px;

      @include breakpoint(desktop-small) {
        max-width: 500px;
      }

      @include breakpoint(desktop-medium) {
        max-width: 350px;
      }

      @include breakpoint(desktop-large) {
        max-width: 495px;
      }

      .container-right {
        align-items: center;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        max-width: 200px;

        @include breakpoint(desktop-small) {
          max-width: 500px;
        }

        @include breakpoint(desktop-medium) {
          max-width: 350px;
        }

        @include breakpoint(desktop-large) {
          max-width: 495px;
        }

        .Input {
          width: 495px;

          input {
            border: transparent;
            color: $black;
            font-family: $primary_font_book;
            font-size: pxToRem(17px);
            height: 43px;
            outline: none;
            width: 100%;

            &::placeholder {
              color: $black;
              font-family: $primary_font_book;
              font-size: pxToRem(17px);
              padding-left: 3px;
            }
          }

          p {
            display: none;
          }
        }

        button {
          background: unset;
          border: unset;
          cursor: pointer;
          margin-right: 5px;
          outline: none;
        }
      }

      .result-search-navbar {
        background-color: $white;
        border-radius: 20px;
        border: 2px solid $blue_magenta;
        cursor: default;
        display: flex;
        flex-direction: column;
        height: auto;
        margin-top: 10px;
        max-width: 100%;
        min-width: 95%;
        padding: 10px 20px;

        .text-search {
          color: $dim_gray;
          font-family: $primary_font_book;
          font-size: pxToRem(16px);
          font-weight: 60;
          padding: 15px 15px;
          width: 100%;

          &:nth-child(2) {
            color: $blue_magenta;
            font-family: $primary_font_book;
            font-size: pxToRem(18px);
            font-weight: 600;
            padding-bottom: 5px;
          }
        }

        .container-search {
          display: flex;
          flex-wrap: wrap;
          padding: 0 0 10px 0;

          .SearchNavbar {
            margin: 10px 5px;
          }
        }
      }
    }

    .div-profile {
      align-items: center;
      display: flex;
      flex-direction: column;

      .navbar-menu-info-profile {
        align-items: flex-start;
        background-color: $white;
        border-radius: 20px;
        border: 2px solid $blue_magenta;
        display: flex;
        flex-direction: column;
        height: 140px;
        justify-content: space-around;
        margin: 50px 6px 0 0;
        padding: 5px 30px 0 10px;
        position: fixed;
        width: 140px;

        button {
          background: transparent;
          border: none;
          color: $blue_magenta;
          cursor: pointer;
          font-family: $primary_font_book;
          font-size: pxToRem(16px);
          font-weight: 600;
          outline: none;
          text-align: left;
        }

        @include breakpoint(desktop-small) {
          margin-right: 40px;
        }

        @include breakpoint(desktop-large) {
          margin: 50px 40px 0 0;
        }
      }
    }

    .button-login {
      align-items: center;
      background: none;
      border-radius: 20px;
      border: none;
      color: $white;
      cursor: pointer;
      display: flex;
      font-family: $primary_font_book;
      font-size: pxToRem(20px);
      height: 40px;
      justify-content: space-around;
      min-width: 132px;
      outline: inherit;
      padding: 0 10px;
      width: 150px;

      p {
        min-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.logged {
        font-size: 1rem;
        padding: 0 15px;

        img {
          border-radius: 50%;
          height: 64px;
          margin-left: 20px;
          width: 64px;
        }
      }
    }
  }

  @include breakpoint(desktop-small) {
    display: flex;
  }
}
