@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/fonts";

.editorClassName{
  overflow: initial !important;
}

.DraftEditor-root {
  border-radius: 2px;
  overflow: initial;
}

.editorClassName{
  border: none;
  height: max-content;
  padding: 14px 14px 14px 23px;
}

.rdw-editor-main {
  min-height: 332px;
}

.public-DraftEditorPlaceholder-inner {
  font-family: $secundary_font;
  font-size: pxToRem(20px);
}
