@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.Playlist {
  min-height: 100vh;
  padding: 114px 0 153px 0;

  .playlist-top {
    padding-left: 25px;

    h2 {
      color: $black_dark;
      font-family: Grotesk, sans-serif;
      font-size: pxToRem(31px);
      font-weight: 500;
    }

    p {
      font-size: pxToRem(14px);
      font-weight: 400;
      margin-top: 24px;
      padding-left: 13px;
    }
  }

  .playlist-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    .CardSlide {
      margin-bottom: 47px;
    }

    .CardFetching {
      height: 358px;
      min-width: 302px;
      width: 302px;
    }
  }
}
