@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/function";

.UserContentFetching {
  max-width: 886px;
  padding: 121px 0 318px 0;

  .content-ft-container {
    .content-ft-title {
      animation: shimmerAnim 2s infinite linear;
      background-image: linear-gradient(to right, #eeeeee 40%, #dddddd 50%, #eeeeee 60%);
      display: flex;
      height: 52px;
      width: 100vh;
    }

    .content-ft-author {
      animation: shimmerAnim 5s infinite linear;
      background-image: linear-gradient(to right, #eeeeee 40%, #dddddd 50%, #eeeeee 60%);
      display: flex;
      height: 26px;
      margin-bottom: 65px;
      margin-top: 20px;
      width: 200px;
    }
  }

  .all-ft-content {
    animation: shimmerAnim 5s infinite linear;
    background-image: linear-gradient(to right, #eeeeee 40%, #dddddd 50%, #eeeeee 60%);
    display: flex;
    height: 60vh;
    width: 100vh;
  }

  .tags-ft-content {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin: 28px 0 109px 0;
    max-width: 886px;
    padding-left: 2px;

    .tag-ft {
      animation: shimmerAnim 5s infinite linear;
      background-image: linear-gradient(to right, #eeeeee 40%, #dddddd 50%, #eeeeee 60%);
      border-radius: 30px;
      box-shadow: 0 1px 3px #00000057;
      display: flex;
      flex-direction: column;
      height: 28px;
      justify-content: space-between;
      padding: 0;
      width: 131px;
    }
  }

  @keyframes shimmerAnim {
    0% {
      background-position: -868px 0;
    }
    100% {
      background-position: 868px 0;
    }
  }
}
