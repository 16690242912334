@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/fonts";

.BluePopUp {
  align-items: flex-end;
  background: #0000003b 0 0 no-repeat padding-box;
  display: none;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  .red {
    background: #F41157 0 0 no-repeat padding-box;
  }

  div {
    align-items: center;
    background: #0505BE 0 0 no-repeat padding-box;
    border-radius: 5px;
    display: flex;
    height: 54px;
    justify-content: space-between;
    margin-bottom: 60px;
    min-width: 655px;

    p {
      color: $white;
      display: block;
      font-size: pxToRem(20px) !important;
      font-weight: 400;
      height: 54px;
      line-height: 54px;
      margin: 0 !important;
      padding: 20px 0px 25px 15px;
      width: 100%;
    }

    button {
      background: none;
      border: none;
      color: $white;
      cursor: pointer;
      font-size: pxToRem(20px);
      font-weight: 700;
      height: 40px;
      margin-right: 20px;
      outline: transparent;
      text-transform: uppercase;
      width: 104px;
    }
  }

  @include breakpoint(desktop-small) {
    display: flex;

    div {
      animation: go-back 1s;
    }
  }
}

@keyframes go-back {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
