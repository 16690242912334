@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.TopAbout {
  align-items: center;
  background-color: #0505bef5;
  display: flex;
  flex-direction: column;
  height: 70vh;
  justify-content: center;

  a {
    text-decoration: none;
  }

  .topContainer {
    align-items: center;
    display: flex;
    height: 80%;
    justify-content: space-between;
    margin-top: 50px;
    width: 87%;

    div {
      height: 100%;
    }

    .textBox {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 996px;

      h2 {
        color: $white;
        font-family: $secundary_font;
        font-size: pxToRem(42px);
        font-weight: 700;
        letter-spacing: -0.4px;
      }

      h6 {
        color: $white;
        font-family: $secundary_font;
        font-size: pxToRem(30px);
        font-weight: 100;
        margin: 20px 0;
        width: 100%;
      }

      .ArrowButton {
        align-items: center;
        background-color: $light_green;
        border: none;
        border-radius: 45px;
        color: $light_black;
        cursor: pointer;
        display: flex;
        font-family: $primary_font_book;
        font-size: pxToRem($search_tag);
        font-weight: 400;
        height: 38px;
        justify-content: center;
        width: 250px;
      }
    }
  }

  .imageBox {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    width: 700px;

    img {
      margin: auto;
      width: 85%;
    }
  }
}
