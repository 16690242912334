@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/fonts.scss";
@import "../../../assets/scss/function.scss";
@import "../../../assets/scss/mixins.scss";

.LearningOfTheDay {
  align-items: center;
  background: $white;
  display: flex;
  flex-direction: column;

  .title-learning {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    p {
      color: $blue_magenta;
      font-family: $secundary_font;
      font-size: pxToRem(30px);
      margin-bottom: 30px;
      margin-left: 6%;
      text-align: right;
    }
  }

  .get-loggin {
    position: relative;

    .modal {
      align-items: center;
      background: #ffffffa1;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0%;
      position: absolute;
      width: 100%;

      .container-modal {
        align-items: center;
        background-color: $white;
        border-radius: 19px;
        box-shadow: 0px 3px 6px #00000029;
        display: flex;
        flex-direction: column;
        height: 323px;
        justify-content: space-evenly;
        max-width: 857px;
        width: 51.5%;

        p {
          font-family: $secundary_font;
          font-size: pxToRem(30px);
          text-align: center;
          max-width: 758px;
        }

        .Button {
          background-color: #cfec59;
          border-radius: 25px;
          color: #2e2e2e;
          display: inline-block;
          font-family: $primary_font_book;
          font-size: pxToRem(17px);
          padding: 0;
          width: 147px;
        }
      }
    }
  }

  .cards-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0 18px;
    justify-content: flex-start;
    width: 87%;

    .ContentCard {
      margin-bottom: 22px;
    }

    &.fetching {
      .fetching-box {
        animation: shimmerAnim 2s infinite linear;
        background-image: linear-gradient(
          to right,
          #eeeeee 40%,
          #dddddd 50%,
          #eeeeee 60%
        );
        box-shadow: 0 1px 3px #00000057;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 606px;
        justify-content: space-between;
        width: 397px;

        @keyframes shimmerAnim {
          0% {
            background-position: -868px 0;
          }

          100% {
            background-position: 868px 0;
          }
        }
      }
    }
  }
}
