@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/function";

.Notes {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 10vh;
  padding: 305px 0 200px 0;

  .empty-note {
    font-size: pxToRem(25px);
    margin-top: 100px;
  }

  .IsFetchingIcon {
    height: 100px;
    width: 100px;

    div {
      height: 100px;
      width: 100px;
    }
  }
}
