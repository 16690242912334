@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.ContentIconList {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 488px;
  margin-right: 140px;
  padding-top: 54px;
  width: 342px;

  .list-title {
    margin-right: 42px;
    padding-bottom: 35px;

    p {
      color: black;
      font-size: pxToRem(15px);
      height: 21px;
      text-align: right;
      width: 198px;
    }
  }

  .content-access {
    align-items: center;
    background-color: #79ACD9;
    border-radius: 30px;
    display: flex;
    height: 52px;
    width: max-content;

    p {
      color: white;
      font-weight: 700;
      padding: 0 8px 0 14px;
      text-transform: uppercase;
    }

    button {
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      height: 52px;
      outline: none;
      padding: 0;
      width: 52px;
    }
  }
}
