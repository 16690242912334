@import "../../../../../assets/scss/variables";
@import "../../../../../assets/scss/fonts";
@import "../../../../../assets/scss/function";


.NotesFetching {
  align-items: flex-end;
  display: flex;
  flex-direction: column;

  .fetching-title-container {
    align-items: flex-end;
    border-left: 2px solid #efeeee;
    display: flex;
    flex-direction: column;
    height: 365px;
    margin-left: 21px;
    padding-bottom: 27px;
    width: 918px;

    .blue-marker {
      position: relative;

      span {
        background-color: gray;
        border-radius: 10px 10px;
        cursor: initial;
        height: 46px;
        left: -922px;
        position: absolute;
        top: 2px;
        width: 6px;
      }
    }
  }
  .fetching-suggestion-head {
    align-items: center;
    animation: shimmerAnim 2s infinite linear;
    background-image: linear-gradient(to right, #eeeeee 40%, #dddddd 50%, #eeeeee 60%);
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    height: 53px;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 889px;
  }

  .fetching-text-fetching {
    align-items: center;
    border-left: 2px solid #b5b5b5;
    display: flex;
    height: 200px;
    margin-bottom: 23px;
    margin-right: 379px;
    text-transform: lowercase;
    width: 475px;

    div {
      animation: shimmerAnim 5s infinite linear;
      background-image: linear-gradient(to right, #eeeeee 40%, #dddddd 50%, #eeeeee 60%);
      height: 177px;
      margin-left: 20px;
      width: 444px;
    }
  }
}

@keyframes shimmerAnim {
  0% {
    background-position: -868px 0;
  }
  100% {
    background-position: 868px 0;
  }
}
