@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/function";

.LogoutUser {
  background-color: $white;
  border-radius: 10pt;
  height: 100%;
  overflow: scroll;
  position: fixed;
  top: 24px;
  transition: visibility 0s, opacity 0.5s ease-in-out;
  width: 100%;
  z-index: 15;

  .logout-top {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 118px;

    div {
      align-items: center;
      background: $blue_magenta 0% 0% no-repeat padding-box;
      border-radius: 50%;
      display: flex;
      height: 148px;
      justify-content: center;
      width: 148px;

      span {
        display: flex;
        justify-content: center;
        width: 100%;

        &:before, &:after {
          background-color: $white;
          border-radius: 5px;
          content: ' ';
          height: 78px;
          position: relative;
          width: 8px;
        }

        &:before {
          left: 5px;
          transform: rotate(45deg);
        }

        &:after {
          left: -4px;
          transform: rotate(-45deg);
        }
      }
    }

    p {
      color: $black_dark;
      font-size: pxToRem(17px);
      margin-top: 62px;
      text-align: center;
      width: 236px;
    }
  }

  .logout-buttons {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 55px;

    button {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #212121;
      border-radius: 33px;
      color: $black_dark;
      font-family: Grotesk, sans-serif;
      font-size: pxToRem(17px);
      font-weight: 600;
      height: 37px;
      margin-bottom: 13px;
      outline: none;
      width: 262px;


      &:nth-child(2) {
        background-color: $light_green;
        border: none;
      }
    }
  }
}
