@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";

.Button {
  border: none;
  border-radius: 28px;
  box-shadow: 0 3px 6px #122F421A;
  color: #212121;
  cursor: pointer;
  font-family: Grotesk, sans-serif;
  font-size: 0.9375rem;
  font-weight: 500;
  height: 56px;
  margin-left: 10px;
  outline: transparent;
  padding: 10px 30px;
  width: 305px;

  @include breakpoint(desktop-small) {
    border-radius: 24px;
    color: $white;
    font-size: pxToRem(20px);
    font-weight: 700;
    height: 48px;
    width: 399px;
  }
}
