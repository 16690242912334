@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";

.CardFetching {
  animation: shimmerAnim 2s infinite linear;
  background-image: linear-gradient(
    to right,
    #eeeeee 40%,
    #dddddd 50%,
    #eeeeee 60%
  );
  box-shadow: 0 1px 3px #00000057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 17px;
  min-width: 100%;
  height: 606px;
  width: 397px;

  @include breakpoint(desktop-small) {
    border-radius: 10px;
    min-width: 325px;
    display: flex;
    height: 490px;
    width: 325px;
  }

  @include breakpoint(desktop-large) {
    height: 606px;
    width: 397px;
  }

  @keyframes shimmerAnim {
    0% {
      background-position: -868px 0;
    }
    100% {
      background-position: 868px 0;
    }
  }
}
