@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.HistoryMobile {
  display: flex;
  padding: 130px 0 66px;

  .history-view-cards {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    min-height: 80vh;
    padding: 0;
    width: 100%;

    .ContentCardMobile {
      margin-bottom: 17px;
      width: 100%;
    }
  }

  @include breakpoint(desktopk-small) {
    display: none;
  }
}
