@import "../../../assets/scss/variables";

.PopUp {
  align-items: center;
  background: #0000003b 0 0 no-repeat padding-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  .content-container {
    align-items: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 300px;
    width: 70%;
  }
}
