@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.ContentMenu {
  align-items: center;
  background-color: $white;
  bottom: 0;
  box-shadow: 0 1px 9px #00000026;
  display: flex;
  height: 59px;
  justify-content: space-evenly;
  position: fixed;
  width: 100%;
  z-index: 10;

  button {
    background-color: $white;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    height: 23px;
    outline: none;
    width: 27px;

    &:first-child {
      margin: 0;
    }
  }

  div {
    display: flex;

    p {
      font-size: pxToRem(14px);
      font-weight: 500;
      margin-left: 2px;
    }
  }

  @include breakpoint(desktop-small) {
    display: none;
  }
}
