@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/function";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/fonts";

.Input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  & > svg {
    cursor: pointer;
    height: 20px;
    width: 20px;
  }

  p {
    opacity: 0;

    &.feedback-message {
      color: $pink_button_mobile;
      font-family: Grotesk, sans-serif;
      font-size: pxToRem(14px);
      margin: 5px 5px 0 0;
      opacity: 0;

      &.visible {
        opacity: 1;
      }
    }
  }

  div {
    display: flex;
    flex-direction: column;

    label {
      font-family: Grotesk, sans-serif;
      font-size: pxToRem($reading_size);
      font-weight: 500;
    }

    input {
      background-color: transparent;
      border: 1px solid $black_dark;
      border-radius: 3px;
      color: $black_dark;
      font-family: Grotesk, sans-serif;
      font-size: pxToRem(18px);
      height: 44px;
      outline: unset;
      padding-left: 10px;
      width: 385px;

      &::placeholder {
        color: #222222;
        font-family: Grotesk, sans-serif;
        font-size: pxToRem(18px);
      }

      &.show-error {
        border-color: $pink_button_mobile;
        color: $pink_button_mobile;

        &::placeholder {
          color: $pink_button_mobile;
        }
      }

      &:disabled {
        border-color: transparent;
      }
    }
  }

  svg {
    bottom: 50%;
    position: absolute;
    right: 15px;
    width: 20px;
  }
}
