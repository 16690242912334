@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.CommentListMobile {
  background-color: $white;
  border-radius: 10pt;
  height: 100vh;
  position: fixed;
  top: 24px;
  transition: visibility 0s, opacity 0.5s linear;
  width: 100%;
  z-index: 15;

  .exit-entry-mobile {
    background: transparent;
    border: none;
    height: 30px;
    margin: 0;
    position: absolute;
    right: 22px;
    top: 13px;
    width: 30px;
    z-index: 11;

    &:before, &:after {
      background-color: $black_dark;
      content: ' ';
      height: 20px;
      left: 15px;
      position: absolute;
      width: 1px;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    @include breakpoint(desktop-small) {
      display: none;
    }
  }

  .comment-list-container {
    height: 80%;
    padding: 27px 0 20px 28px;

    p {
      color: $black_dark;
      font-size: pxToRem($small_mobile_text);
      font-weight: 600;
      letter-spacing: 0.5px;
    }

    .comment-list {
      height: 100%;
      margin-top: 32px;
      overflow-y: scroll;
    }
  }

  button {
    background-color: $white;
    border: none;
    border-top: 1px solid $gray_register;
    bottom: 0;
    color: $gray_register;
    height: 63px;
    margin-top: 20px;
    outline: none;
    padding-left: 25px;
    position: fixed;
    text-align: left;
    width: 100%;
  }
}
