@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.NavigationLink {
  color: $white;
  font-family: $primary_font_book;
  font-size: pxToRem($small_mobile_text);
  padding-bottom: 7px;
  text-decoration: none;
  text-transform: uppercase;
  
  @include breakpoint(desktop-small) {
    font-family: $primary_font_book;
    font-size: 18px;
    text-transform: unset;
  }

  @include breakpoint(desktop-medium) {
    font-size: 20px;
  }
  
  &.active, &:hover {
    box-shadow: 0 4px 0 -2px $white;
  }
}
