@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";

.BottomAbout {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  h2 {
    color: $black;
    font-family: $secundary_font;
    font-size: pxToRem(38px);
    font-weight: 700;
    letter-spacing: -0.4px;
    margin: 0;
  }

  h3 {
    color: $black;
    font-family: $secundary_font;
    font-size: pxToRem(28px);
    font-weight: 700;
    letter-spacing: -0.4px;
    margin: 10px 0;
  }

  p {
    font-family: $secundary_font;
    font-size: pxToRem(25px);
    line-height: 35px;
  }

  .cardContainer {
    align-items: center;
    display: flex;
    height: 326px;
    justify-content: space-between;
    margin: 105px 0;
    width: 80%;

    .imageBox {
      background-color: #EFEFEF;
      display: flex;
      height: 326px;
      justify-content: center;
      max-width: 762px;
      width: 50%;
      
      img {
        max-width: 762px;
        width: 100%;
      }
    }

    .textBox {
      display: flex;
      flex-direction: column;
      height: 40%;
      justify-content: space-around;
      max-width:720px;
      width: 50%;
    }
  }
}
