@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/function";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/fonts";

.UsernameSignUp {
  align-items: center;
  background-color: $white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  position: absolute;
  top: 0;

  h3 {
    color: $black_dark;
    font-size: pxToRem(20px);
    font-weight: 500;
  }

  p {
    color: $black_dark;
    font-size: pxToRem(15px);
    height: 40px;
    margin-top: 23px;
    opacity: 60%;
    text-align: center;
    text-indent: 3px;
    width: 298px;
  }

  .username-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 49px;
    width: 100%;

    div {
      align-items: flex-end;
      border-bottom: 1px solid  blue;
      display: flex;
      padding-left: 10px;
      width: 100%;

      p {
        font-weight: 700;
        height: 25px;
        margin: 0;
        opacity: 1;
        width: 0;
      }

      input {
        border: 0;
        height: 24px;
        margin-left: 20px;
        outline: none;
        width: 100%;
      }

      .checked-input {
        background-image: url("../../../../../src/assets/images/checked-approved.svg");
        background-size: cover;
        border: 0;
        display: none;
        height: 13px;
        margin-bottom: 8px;
        margin-right: 4px;
        width: 12px;
      }
    }

    .Button{
      align-items: center;
      background-color: $light_green;
      border-radius: 33px;
      color: $black;
      cursor: auto;
      font-size: pxToRem(17px);
      font-weight: 500;
      height: 37px;
      justify-content: center;
      margin-top: 40px;
      transition: opacity 0.5s ease-out;
      width: 182px;
    }

    .block-invisible {
      height: 37px;
      justify-content: center;
      margin-top: 40px;
      pointer-events: none;
      width: 182px;
    }
  }
}
