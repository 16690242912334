@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/fonts";

.CommentTextMobile {
  background-color: $white;
  border-radius: 10pt;
  height: 100%;
  overflow: scroll;
  position: fixed;
  top: 24px;
  transition: visibility 0s, opacity 0.5s ease-in-out;
  width: 100%;
  z-index: 15;

  .exit-entry-mobile {
    background: transparent;
    border: none;
    height: 30px;
    margin: 0;
    position: absolute;
    right: 22px;
    top: 11px;
    width: 30px;
    z-index: 11;

    &:before, &:after {
      background-color: $black_dark;
      content: ' ';
      height: 20px;
      left: 15px;
      position: absolute;
      width: 1px;
    }

    &:before {
      transform: rotate(-90deg)
    }

    &:after {
      display: none;
    }

    @include breakpoint(desktop-small) {
      display: none;
    }
  }

  .create-comment-container {
    height: 80%;
    padding: 27px 0 20px 28px;

    p {
      font-size: pxToRem(13px);
    }

    .comment-audio {
      display: flex;
      justify-content: space-evenly;
    }

    .create-comment-inputs {
      margin-top: 77px;
      width: 90%;

      form {
        height: 100%;

        div {
          height: 100%;
          margin-top: 30px;

          label {
            font-family: Grotesk, sans-serif;
            font-size: pxToRem(15px);
            font-weight: 700;
            letter-spacing: 0.2px;
            margin-left: 10px;
          }

          textarea {
            background-color: #F2F2F2;
            border: 1px solid #B4B4B4;
            border-radius: 13px;
            height: 95px;
            margin-top: 10px;
            outline: none;
            resize: none;
            width: 100%;
          }
        }

        .Input {
          .error-tag {
            border: 1px solid red;
            opacity: 0.5;
          }

          input {
            background-color: #F2F2F2;
            border: 1px solid #B4B4B4;
            border-radius: 13px;
            margin-top: 10px;
            outline: none;
            width: 98%;
          }
        }

        .container-search-tags {
          flex-direction: column;
          margin: 0;
          width: 100%;

          .SearchTagComment {
            margin-top: 15px;

            p {
              padding: 0;
            }
          }
        }

        .container-delete-tags {
          margin-top: 0;
          padding: 0;

          .DeleteTag {
            border-radius: 13px;
            margin: 10px 0 0 5px;

            button {
              height: auto;
              margin: 0;
              padding: 4px;
              position: initial;
            }
          }
        }

        .submit-container {
          display: flex;
          justify-content: center;
          padding-bottom: 40px;
          width: 100%;

          .Button {
            border: none;
            box-shadow: none;
            color: $black_dark;
            font-size: pxToRem(15px);
            font-weight: 500;
            height: 37px;
            position: initial;
            width: 191px;
          }
        }
      }
    }
  }
}
