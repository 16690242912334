@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/fonts";

.SuggestionTags {
  justify-content: center;
  width: 100%;
  z-index: 10;

  .suggestion-container {

    .Button {
      border-radius: 3px;
      color: black;
      font-family: $secundary_font;
      font-size: pxToRem(14px);
      margin: 0;
      padding: 0;
      width: 327px;
    
      button {
        margin: 0;
        width: 327px;
      }
    }

    .Input {
      margin-top: 0 !important;

      .feedback-message  {
        display: none;
      }
    }

    .title-tags {
      align-items: center;
      display: flex;

      h5 {
        font-family: $secundary_font;
        font-size: pxToRem(20px);
        font-weight: 700;
      }
  
      p {
        align-items: center;
        background-color: $strong_blue;
        border-radius: 2px;
        color: white;
        display: flex;
        font-family: $secundary_font;
        font-size: pxToRem(14px);
        height: 30px;
        justify-content: center;
        margin-left: 35px;
        opacity: 0.6;
        width: 317px;
      }
    }
  
    .container-search-tags {
      border-radius: 0 10px 10px 0;
      border: 1px solid #FAFAFA;
      display: flex;
      flex-direction: column;
      margin-bottom: 23px;
      position: absolute;
    }
  
    .container-delete-tags {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 20px;
    }

    .insert-tag {
      color: #9F9F9F;
      font-family: $secundary_font;
      font-size: pxToRem(14px);
      margin-top: 8px;
      position: absolute;
    }
  }
}
