@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/fonts";

.GreyPopUp {
  align-items: center;
  background: #0000003b 0 0 no-repeat padding-box;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;

  div {
    align-items: center;
    backdrop-filter: blur(30px);
    background: #1E1E1EBF 0 0 no-repeat padding-box;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 129px;
    min-width: 270px;
    -webkit-backdrop-filter: blur(30px);

    p {
      color: $white;
      font-size: pxToRem($small_mobile_text);
      font-weight: 400;
      margin-top: 40px;
      text-align: center;
      width: 236px;
    }

    button {
      align-items: center;
      background: none;
      border: none;
      border-radius: 0;
      border-top: 1px solid $dim_gray;
      color: $white;
      cursor: pointer;
      display: flex;
      font-size: pxToRem(20px);
      font-weight: 700;
      height: 44px;
      justify-content: center;
      margin: 0;
      outline: transparent;
      text-transform: uppercase;
      width: 100%;

      span {
        display: flex;
        justify-content: center;
        width: 100%;

        &:before, &:after {
          background-color: $light_green;
          content: ' ';
          height: 20px;
          position: relative;
          width: 2px;
        }

        &:before {
          height: 20px;
          left: 6px;
          transform: rotate(45deg);
        }

        &:after {
          bottom: -7px;
          height: 12px;
          left: -7px;
          transform: rotate(-45deg);
        }
      }
    }
  }

  @include breakpoint(desktop-small) {
    display: none;
  }
}
