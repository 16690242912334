@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/fonts";

.UserMenu {
  align-items: center;
  background-color: #191919;
  bottom: 0;
  box-shadow: 0 3px 6px #0000000A;
  display: flex;
  height: 67px;
  justify-content: space-evenly;
  position: fixed;
  width: 100%;
  z-index: 15;

  div {
    button {
      background-color: transparent;
      background-repeat: no-repeat;
      border: none;
      height: 29px;
      width: 47px;
    }

    p {
      color: #A4A4A4;
      font-size: pxToRem(12px);
    }

    &.home-active {
      p {
        color: $white;
      }
    }

    &.library-active {
      p {
        color: $white;
      }
    }

    &.profile-active {
      p {
        color: $white;
      }
    }
  }

  @include breakpoint(desktop-small) {
    display: none;
  }
}
