@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/function";

.ResearchInformation {
  align-items: center;
  background-color: #3737cb;
  display: none;
  min-height: 82px;
  padding: 100px 3px 3px 135px;
  position: fixed;
  width: 100%;
  z-index: 9;

  p {
    color: $white;
    font-size: pxToRem(18px);
    font-weight: 500;
    width: 214px;
  }

  h3 {
    color: $white;
    font-size: pxToRem(26px);
    font-weight: 400;
    width: 100%;
  }

  .tags-result {
    display: flex;
    height: 100%;
    margin-left: 121px;
    margin-right: 259px;
    padding: 3px 0 3px 0;
    width: max-content;

    .adjust-tags {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 11px 24px;
      overflow-wrap: normal;
      width: 100%;
    }
  }

  @include breakpoint(desktop-small) {
    display: flex;
  }
}
