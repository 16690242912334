@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";

.GenericCreationCard {
  align-items: center;
  border-radius: 10px;
  box-shadow: 50px 50px 99px #2929290F;
  display: flex;
  flex-direction: column;
  height: 311px;
  justify-content: center;
  width: 400px;

  .creation-button {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 312px;

    button {
      background-color: transparent;
      background-image: url("../../../assets/images/add-journey.svg");
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      height: 103px;
      margin-bottom: 20px;
      outline: none;
      width: 103px;
    }

    p {
      color: black;
      font-size: pxToRem(28px);
      font-weight: 400;
      margin-bottom: 24px;
      text-align: center;
    }
  }

}
