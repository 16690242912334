@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/function";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/fonts";

.CardSlide {
  border-radius: 9px;
  box-shadow: 0 0 12px #0000001C;
  height: 358px;
  width: 302px;

  @include breakpoint(tablet-large) {
    box-shadow: none;
    cursor: pointer;
    height: 515px;
    width: 378px;
  }

  .top-card-slide {
    border-radius: 9px !important;
    overflow: hidden;
    position: relative;

    p {
      bottom: 49px;
      color: white;
      font-size: pxToRem(96px);
      font-weight: 700;
      left: 21px;
      opacity: 0.6;
      position: absolute;
      z-index: 1;
    }

    img {
      border-radius: 9px 9px 0 0;
      height: 153px;
      min-height: 213px;
      min-width: 302px;
      width: 302px;
    }

    @include breakpoint(tablet-large) {
      &::after {
        background-color: rgba(22, 38, 48, 0.6);
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
      }

      p {
        z-index: 3;
      }

      img {
        position: relative;
        transition: transform .5s ease;
        width: 378px;

        &.show {
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
      }

      .save-icon {
        height: 50px;
        min-height: 50px;
        min-width: 50px;
        position: absolute;
        right: 21px;
        top: 15px;
        width: 50px;
        z-index: 3;
      }
    }
  }

  .info-card-slide {
    display: flex;
    flex-direction: column;
    padding: 15px 0 0 18px;
    width: 100%;

    @include breakpoint(tablet-large) {
      padding: 0;
    }

    .user-card-slide {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @include breakpoint(tablet-large) {
        margin: 8px 1px;
      }

      .container-tag {
        display: none;

        @include breakpoint(tablet-large) {
          display: flex;
        }

        .Tag {
          align-items: center;
          background: $light_green !important;
          border: none;
          color: $black;
          cursor: pointer;
          display: block;
          font-family: Grotesk, sans-serif;
          font-size: pxToRem(11px);
          font-weight: 500;
          height: 22px;
          min-width: 125px;
          overflow: hidden;
          padding: 5px;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 125px;
        }
      }

      div {
        display: flex;

        img {
          border-radius: 50%;
          height: 28px;
          text-align: center;
          width: 30px;

          @include breakpoint(tablet-large) {
            height: 34px;
            width: 34px;
          }
        }

        p {
          -webkit-line-clamp: 1;
          display: flex;
          font-family: Roboto, sans-serif;
          font-size: pxToRem(13px);
          font-weight: 100;
          margin: 0;
          max-height: 44px;
          padding: 6px 0 0 10px;

          strong {
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            display: block;
            max-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      button {
        background-size: contain;
        background: url("../../../../assets/images/polka-dot-button.svg") white no-repeat center;
        border: none;
        display: none;
        height: 21px;
        margin: 3px 0 0 31px;
        outline: none;
        width: 23px;
      }
    }

    .title-card-slide {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 13px 0 0 3px;
      min-height: 73px;

      p {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        font-family: Grotesk, sans-serif;
        font-size: pxToRem(20px);
        font-weight: 700;
        margin: 0;
        max-width: 252px;
        opacity: 1;
        overflow: hidden;
        padding: 0;
        text-overflow: ellipsis;

        &:nth-of-type(2) {
          -webkit-line-clamp: 1;
          font-family: Grotesk, sans-serif;
          font-size: pxToRem(13px);
          font-weight: 500;
          letter-spacing: 0.01px;
          margin-top: 10px;
          max-width: 252px;
          opacity: 0.6;
        }
      }

      strong {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        font-family: Grotesk, sans-serif;
        font-size: pxToRem(13px);
        font-weight: 500;
        letter-spacing: 0.01px;
        margin-top: 10px;
        max-width: 252px;
        opacity: 0.6;
        overflow: hidden;
        text-overflow: ellipsis;

        p {
          -webkit-line-clamp: 1;
          font-family: Grotesk, sans-serif;
          font-size: pxToRem(13px);
          font-weight: 500;
          letter-spacing: 0.01px;
        }
      }

      @include breakpoint(tablet-large) {
        display: none;
      }
    }

    .title-card-desktop {
      display: none;
      flex-direction: column;
      justify-content: flex-start;
      margin: 10px 3px;
      min-height: 73px;

      p {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        color: #212121;
        display: -webkit-box;
        font-family: $secundary_font;
        font-size: pxToRem(25px);
        font-weight: 700;
        height: 63px;
        margin-top: 29px;
        margin: 0;
        max-width: 252px;
        opacity: 1;
        overflow: hidden;
        padding: 0;
        text-overflow: ellipsis;
      }

      strong {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        font-family: $secundary_font;
        font-size: pxToRem(17px);
        font-weight: 500;
        letter-spacing: 0.01px;
        margin-top: 6px;
        opacity: 0.6;
        overflow: hidden;
        padding: 5px 23px 0 0;
        text-overflow: ellipsis;
      }

      @include breakpoint(tablet-large) {
        display: flex;
      } 
    }

    .about-slide-card {
      background-color: $white;
      opacity: 0;
      pointer-events: none;
      position: fixed;

      &.show {
        bottom: 30px;
        min-height: 146px;
        opacity: 1;
        pointer-events: visible;
        transform: translateY(-12px);
        transition: transform .3s linear;
      }

      .text-about {
        align-items: flex-start;
        display: flex;
        flex-direction: column;

        a {
          border-bottom: 1px solid $white;
          color: $white;
          cursor: pointer;
          font-weight: 700;
          overflow: visible;
        }

        p {
          -webkit-box-orient: vertical;
          color: #ffffffc7;
          line-height: 16px;
          margin: 0;
          max-height: 62px;
          opacity: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .buttons-about {
        display: flex;
        flex-direction: column;
        gap: 11px 0;
        margin: 21px 0 0 0;

        button {
          align-items: center;
          background-color: $white;
          border-radius: 29px;
          border: 1px solid #212121;
          color: $black_dark;
          cursor: pointer;
          display: flex;
          font-family: $secundary_font;
          font-size: pxToRem(19px);
          height: 57px;
          opacity: 0.8;
          outline: none;
          text-align: left;
          width: 375px;

          img {
            height: 36px;
            margin: 0 8px 0 30px;
            width: 36px;
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .bottom-card-slide {
      display: flex;
      justify-content: space-between;
      width: 86%;

      .tags-card-slide {
        display: flex;
        margin-top: 27px;

        .Tag {
          align-items: center;
          background-color: $light_green;
          border: none;
          color: $black;
          cursor: pointer;
          display: block;
          font-family: Grotesk, sans-serif;
          font-size: pxToRem(11px);
          font-weight: 500;
          height: 22px;
          min-width: 125px;
          overflow: hidden;
          padding: 5px;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 125px;

          &:nth-of-type(2) {
            margin-left: 19px;
          }
        }
      }

      .buttons-card-slide {
        display: none;
        margin: 23px 0 0 26px;

        button {
          align-items: center;
          background-color: unset;
          background-image: url("../../../../assets/images/seelater-icon.svg");
          background-repeat: no-repeat;
          background-size: cover;
          border: none;
          display: flex;
          height: 26px;
          justify-content: center;
          outline: none;
          width: 26px;
        }
      }

      @include breakpoint(tablet-large) {
        display: none;
      }
    }
  }
}

.CardSlide-empty {
  align-items: center;
  background: #2E2E2E;
  border-radius: 9px;
  box-shadow: 0 0 12px #0000001C;
  color: white;
  display: flex;
  height: 358px;
  justify-content: center;
  width: 378px;
}
