@import "../../../../../assets/scss/variables";
@import "../../../../../assets/scss/fonts";
@import "../../../../../assets/scss/function";

.ResultTag {
  align-items: center;
  border: 2px solid #FFFFFF;
  border-radius: 33px;
  cursor: pointer;
  display: flex;
  height: 40px;
  padding-left: 15px;
  width: auto;

  svg {
    height: 22px;
    width: 22px;
  }

  p {
    font-size: pxToRem(21px);
    font-weight: 400;
    padding-left: 10px;
    padding-right: 21px;
    width: auto;
  }
}
