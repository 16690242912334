@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.ContentCardMobile {
  box-shadow: 0 0 12px #0000001C;
  display: flex;
  height: 121px;
  justify-content: center;
  padding: 22px 0 29px 0;
  width: 100%;

  .left-card-mobile {
  height: 80px;

    .user-card-mobile {
      display: flex;
      justify-content: flex-start;

      img {
        border-radius: 50%;
        height: 28px;
        text-align: center;
        width: 30px;
      }

      p {
        font-family: Roboto, sans-serif;
        font-size: pxToRem(13px);
        font-weight: 100;
        margin: 0;
        padding: 5px 0 0 4px;
        width: 212px;
      }
    }

    h3 {
      display: -webkit-box;
      font-family: Grotesk, sans-serif;
      font-size: pxToRem(20px);
      font-weight: 700;
      height: 50px;
      margin: 7px 0 0 0;
      max-width: 249px;
      opacity: 1;
      overflow: hidden;
      padding: 0;
      text-overflow: ellipsis;
      width: 249px;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    .infos-card-mobile {
      display: flex;
      justify-content: space-between;
      margin-top: 18px;

      div {
        display: flex;

        p {
          color: $black_dark;
          font-family: Roboto, sans-serif;
          font-size: pxToRem(11px);
          font-weight: 100;
          opacity: 0.6;
          padding-top: 4px;
          text-transform: lowercase;

          &:nth-of-type(2) {
            margin-left: 5px;
          }
        }

        svg {
          height: 20px;
          margin-left: 7px;
          width: 20px;
        }
      }
    }
  }
  
  .tag-card-mobile {
    position: absolute;

    .Tag {
      align-items: center;
      background-color: $light_green;
      border: none;
      color: $black;
      cursor: pointer;
      display: block;
      font-family: Grotesk, sans-serif;
      font-size: pxToRem(11px);
      font-weight: 500;
      height: 22px;
      margin-right: 13%;
      min-width: 127px;
      overflow: hidden;
      padding: 5px;
      position: relative;
      right: -30px;
      text-overflow: ellipsis;
      top: 103px;
      white-space: nowrap;
      width: 127px;
    }
  }

  .right-card-mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 0 0 9px;

    img {
      height: 72px;
      min-height: 72px;
      min-width: 68px;
      width: 68px;
    }

    .buttons-card-mobile {
      display: none;
      margin-top: 21px;

      button {
        align-items: center;
        background-color: unset;
        background-image: url("../../../assets/images/flag-transparent.svg");
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
        display: flex;
        height: 19px;
        justify-content: center;
        outline: none;
        width: 19px;

        &:nth-of-type(2) {
          background-image: url("../../../assets/images/polka-dot-button.svg");
          background-size: contain;
          height: 21px;
          margin-left: 28px;
          margin-top: 8px;
          width: 23px;
        }
      }

      @include breakpoint(mobile-medium) {
        button {
          &:nth-of-type(2) {
            margin-left: 30px;
          }
        }
      }
    }
  }

  @include breakpoint(tablet) {
    justify-content: space-evenly;
  }
}
