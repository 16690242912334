@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.LargeMobileCard {
  margin-bottom: 10px;

  p {
    font-size: pxToRem(16px);
    font-weight: 400;
    line-height: 28px;
    margin-top: 63px;
    padding-left: 25px;
    width: 299px;
  }

  .card-large {
    align-items: center;
    box-shadow: 0 1px 9px #00000026;
    display: flex;
    flex-direction: column;
    height: 376px;
    margin-top: 33px;
    padding: 23px 25px 0 25px;

    img {
      background-size: cover;
      height: 152px;
      width: 100%;
    }

    .info-card-large {
      display: flex;
      flex-direction: column;
      padding: 16px 0 0 29px;
      width: 100%;

      .user-card-large {
        display: flex;
        justify-content: flex-start;

        img {
          border-radius: 50%;
          height: 28px;
          text-align: center;
          width: 30px;
        }

        p {
          font-family: Roboto, sans-serif;
          font-size: pxToRem(13px);
          font-weight: 100;
          margin: 0;
          padding: 0 0 0 13px;
        }
      }

      .title-card-large {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 2px;

        p {
          display: -webkit-box;
          font-family: Grotesk, sans-serif;
          font-size: pxToRem(20px);
          font-weight: 700;
          margin: 0;
          max-width: 284px;
          opacity: 1;
          overflow: hidden;
          padding: 0;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;

          &:nth-of-type(2) {
            font-family: Grotesk, sans-serif;
            font-size: pxToRem(13px);
            font-weight: 500;
            letter-spacing: 0.01px;
            margin-top: 0;
            max-width: 284px;
            opacity: 0.6;
            -webkit-line-clamp: 1;
          }
        }
      }

      .bottom-card-large {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;

        .tags-card-large {
          display: flex;

          .Tag {
            align-items: center;
            background-color: $light_green;
            border: none;
            color: $black;
            cursor: pointer;
            display: flex;
            font-family: Grotesk, sans-serif;
            font-size: pxToRem(11px);
            font-weight: 500;
            height: 22px;
            padding: 0;
            width: 97px;

            &:nth-of-type(2) {
              margin-left: 19px;
            }
          }
        }

        .buttons-card-large {
          display: none;
          margin-right: 13px;

          button {
            align-items: center;
            background-color: unset;
            background-image: url("../../../assets/images/flag-transparent.svg");
            background-repeat: no-repeat;
            background-size: cover;
            border: none;
            display: flex;
            height: 19px;
            justify-content: center;
            outline: none;
            width: 19px;

            &:nth-of-type(2) {
              background-image: url("../../../assets/images/polka-dot-button.svg");
              background-size: contain;
              height: 21px;
              margin: 8px 0 0 31px;
              width: 23px;
            }
          }

          @include breakpoint(mobile-medium) {
            button {
              &:nth-of-type(2) {
                margin-left: 31px;
              }
            }
          }
        }
      }
    }
  }

  .fetching-card-large {
    animation: shimmerAnim 2s infinite linear;
    background-image: linear-gradient(to right, #eeeeee 40%, #dddddd 50%, #eeeeee 60%);
    box-shadow: 0 1px 3px #00000057;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 376px;
    justify-content: space-between;
    margin-top: 33px;

    @keyframes shimmerAnim {
      0% {
        background-position: -868px 0;
      }
      100% {
        background-position: 868px 0;
      }
    }
  }
}
