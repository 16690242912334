@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.ContentComment {
  border-bottom: 1px solid #F5F5F5;
  border-top: 1px solid #F5F5F5;

  .user-comment {
    display: flex;
    padding-top: 32px;

    .img-user-comment {
      display: none;
      height: 100%;
      margin-right: 27px;

      img {
        border-radius: 30px;
        height: 52px;
        width: 52px;
      }

      @include breakpoint(desktop-small) {
        display: flex;
      }
    }

    .infos-comment {
      color: $black;

      .info-user-comment {
        align-items: center;
        display: flex;
        width: auto;

        img {
          border-radius: 50%;
          height: 30px;
          text-align: center;
          width: 32px;
        }

        strong {
          font-family: Roboto, sans-serif;
          font-size: pxToRem($reading_size);
          height: min-content;
          margin-left: 11px;
          word-break: break-word;
        }

        p {
          color: $black_dark;
          display: flex;
          font-family: Roboto, sans-serif;
          font-size: pxToRem(12px);
          font-weight: 100;
          margin: 0;
          opacity: 0.6;
          padding: 0 0 0 5px;
          word-break: unset;
        }

        @include breakpoint(desktop-small) {
          display: none;
        }
      }


      .name-user-comment {
        display: none;
        font-size: pxToRem(20px);
        font-weight: 700;
        letter-spacing: -1px;
        margin-bottom: 17px;
        text-transform: lowercase;

        @include breakpoint(desktop-small){
          display: flex;
        }
      }

      .select-comment {
        align-items: center;
        background-color: #F2F2F2;
        border-radius: 13px;
        display: flex;
        height: min-content;
        margin-bottom: 7px;
        margin-left: 30px;
        min-height: 58px;
        padding-left: 10px;
        width: 278px;

        span {
          align-items: center;
          border-left: 1px solid #b5b5b5;
          display: flex;
          margin: 10px 0 10px 0;
          min-height: 20px;
          padding-left: 14px;
        }

        p {
          font-size: pxToRem(13px);
          font-style: italic;
          font-weight: 400;
          letter-spacing: 0;
        }

        @include breakpoint(desktop-small) {
          align-items: center;
          background-color: $white;
          display: flex;
          height: min-content;
          margin-bottom: 23px;
          margin-left: 0;
          text-transform: lowercase;
          width: 475px;

          span {
            border-left: 2px solid #b5b5b5;
            display: flex;
            margin: 10px 0 10px 0;
            min-height: 20px;
            padding-left: 18px;
          }

          p {
            color: #9e9e9e;
            font-size: pxToRem(20px);
            font-style: italic;
            text-align: left;
          }
        }
      }

      .description-comment {
        margin-left: 41px;
        padding-bottom: 22px;
        width: 278px;

        p {
          color: black;
          font-size: pxToRem(13px);
          font-weight: 400;
          text-align: left;
        }

        @include breakpoint(desktop-small) {
          margin-left: 11px;
          padding-bottom: 22px;

          p {
            color: black;
            font-size: pxToRem(20px);
            text-align: left;
            text-transform: lowercase;
            width: 685px;
          }
        }
      }

      @include breakpoint(desktop-small) {
        padding: 0;
      }
    }

    @include breakpoint(desktop-small) {
      width: 768px;
    }
  }

  .tags-comment {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    height: 50%;
    margin-left: 25px;
    padding: 0 0 20px 0;

    .Tag {
      align-items: center;
      background-color: $light_green;
      border: none;
      color: $black;
      cursor: pointer;
      display: flex;
      font-family: Grotesk, sans-serif;
      font-size: pxToRem(11px);
      font-weight: 500;
      height: 22px;
      margin: 0;
      min-width: 115px;
      padding: 0 20px;
      position: initial;
      width: fit-content;
    }

    @include breakpoint(desktop-small) {
      margin-left: 80px;
    }
  }
}
