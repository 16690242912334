@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/function";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/fonts";

.RecoveryInputCode {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding-left: 25px;

    h2 {
      display: flex;
      font-size: pxToRem(20px);
      font-weight: 700;
      justify-content: flex-start;
      letter-spacing: 0;
      padding-bottom: 12px;
      text-align: left;
      width: 146px;

      @include breakpoint(desktop-small) {
        font-size: pxToRem(31px);
        letter-spacing: -1.99px;
        width: 100%;
      }
    }

    p {
      color: $dim_gray;
      font-family: Grotesk, sans-serif;
      font-size: pxToRem(13px);
      font-weight: 500;
      letter-spacing: 0;
      line-height: 26px;
      padding-bottom: 44px;
      width: 264px;

      @include breakpoint(desktop-small) {
        color: $black_dark;
        font-size: pxToRem(18px);
        width: 400px;
      }
    }

  .code-container {
    display: flex;
    gap: 8px;

    .otp-field {
      border-radius: 4px;
      font-family: Grotesk, sans-serif;
      font-size: pxToRem(20px);
      font-weight: 700;
      height: 53px;
      padding: 0;
      text-align: center;
      width: 46px;

      &:valid {
        border: 1px solid #2222224D;
      }

      &:focus {
        border: 1px solid  $light_green;
        outline: none;
      }
    }

    input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    input[type=number] {
      -moz-appearance: textfield;
      appearance: textfield;
    }

    @include breakpoint(desktop-small) {
      .otp-field {
        height: 44px;
        padding: 0 5px 0 7px;
      }
    }
  }

  .Button {
    border-radius: 0;
    box-shadow: none;
    color: $dim_gray;
    font-size: pxToRem(13px);
    font-weight: 500;
    letter-spacing: 0.02px;
    margin-top: 49px;
    outline: none;
    padding: 0;
    width: 100%;

    &:nth-child(1) {
      border-radius: 33px;
      color: $black;
      font-size: pxToRem(17px);
      font-weight: 500;
      height: 37px;
      margin: 0;
      width: 182px;
    }

    @include breakpoint(desktop-small){
      color: $black_dark;
      font-size: pxToRem(20px);
      font-weight: 600;

      &:nth-child(1) {
        border-radius: 0;
        box-shadow: none;
        color: $black_dark;
        font-size: pxToRem(20px);
        font-weight: 700;
        height: 48px;
        letter-spacing: 0.02px;
        margin-top: 14px;
        outline: none;
        padding: 0;
        width: 100%;
      }
    }
  }

  .comeback-login {
    text-align: center;

    @include breakpoint(desktop-small) {
      width: 100%;
    }
  }
}
