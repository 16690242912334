@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.ContentText {
  padding: 0 23px;

  .button-container {
    display: flex;
    justify-content: center;
    width: 100%;

    .Button {
      background-color: $strong_blue;
      margin: 20px 0 20px 0;
      width: 100%;
    }
  }

  .url-container {
    p {
      font-size: pxToRem(15px);
      line-height: 15px;
      margin-bottom: 50px;
    }

    a {
      margin-left: 10px;
      text-decoration: none;
    }
  }

  p {
    color: black;
    font-size: pxToRem(16px);
    font-weight: 400;
    letter-spacing: 0.01px;
    line-height: 29px;
    margin-bottom: 15px;
    width: 100%;
  }

  .anchor-button {
    button {
      background-color: unset;
      background-image: url("../../../assets/images/anchor-icon.png");
      background-size: cover;
      border: none;
      cursor: pointer;
      height: 30px;
      margin-bottom: 30px;
      margin-top: 10px;
      outline: none;
      width: 99px;
    }
  }

  .references-link {
    display: block;
    font-family: Grotesk, sans-serif;
    margin: 10px 0 20px 0;

    p {
      font-weight: 700;
    }

    div {
      margin-top: 10px;
      display: inline-block;
      overflow: hidden !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;

      a {
        color: #0505BE;
        outline: none;
      }
    }
  }

  @include breakpoint(desktop-small) {
    margin-bottom: 101px;
    padding: 0;

    p {
      color: black;
      font-size: pxToRem(20px);
      font-weight: 400;
      letter-spacing: 0.01px;
      line-height: 29px;
      margin-bottom: 15px;
      width: 858px;
    }
  }
}
