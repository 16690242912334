@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/function";

.HistoryFilter {
  padding: 0 0 66px 105px;
  width: 22%;

  h2 {
    font-size: pxToRem(32px);
    font-weight: 600;
    padding-left: 16px;
  }

  p {
    font-size: pxToRem(24px);
    font-weight: 600;
    padding: 23px 0 5px 16px;
  }

  .checkbox-days-history {
    margin-top: 37px;
    padding-left: 20px;

    .Checkbox {
      margin-top: 22px;
    }
  }
}
