@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/function";
@import "../../../../assets/scss/mixins";

.AddInCollectionPopUp {
  align-items: center;
  background: #0000003b 0 0 no-repeat padding-box;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  .container-add-journey-popup {
    background-color: $white;
    border-radius: 10px;
    box-shadow: 50px 50px 99px #2929290F;
    margin-top: 80px;
    width: 630px;

    .header-add-journey-popup {
      display: flex;
      justify-content: flex-end;

      .exit-container-journey {
        background-color: unset;
        background-image: url("../../../../assets/images/blue-exit-icon.svg");
        background-position: center;
        background-size: cover;
        border: none;
        cursor: pointer;
        height: 52px;
        margin: 23px 17px 0 0;
        outline: none;
        width: 70px;
      }
    }

    .body-add-journey-popup {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      padding: 0 114px;

      h2 {
        color: $black_dark;
        font-family: $secundary_font;
        font-size: pxToRem(30px);
        width: 286px;
      }

      p {
        color: $black_dark;
        font-size: pxToRem(22px);
        font-weight: 500;
        margin-top: 16px;
      }

      .Input {
        margin-top: 35px;

        input {
          border-radius: 0;
          border: 3px solid #D8D8D8;
          height: 51px;
          padding-left: 10px;
          width: 384px;

          &:focus {
            border: 3px solid $light_green;
          }

          &::placeholder {
            color: #A2A2A2;
            font-family: $secundary_font;
            font-size: pxToRem(20px);
            font-weight: 400;
            letter-spacing: 0.02px;
            margin-left: 20px;
          }
        }
      }

      .Button {
        border-radius: 10px;
        color: $black_dark;
        font-family: $secundary_font;
        height: 45px;
        margin: 25px 0 35px 0;
      }

      .LoaderButton {
        border-radius: 10px;
        height: 45px;
        margin: 25px 0 35px 0;
      }

      .Loader {
        @include breakpoint(desktop-small) {
          display: flex;
          height: 200px;
          position: initial;
        }
      }

      .container-add-journey {
        align-items: center;
        border: 1px solid #070706;
        display: flex;
        flex-direction: column;
        height: 303px;
        margin-top: 10px;
        overflow-y: scroll;
        padding: 0 0 50px 0;
        width: 395px;

        .Button {
          color: white;
        }

        &.empty {
          border: none;
          overflow: hidden;
          text-align: center;
        }

        div {
          border-bottom: 3px solid #A2A2A2;
          cursor: pointer;
          display: flex;
          padding: 8px 0 8px 15px;
          width: 90%;

          &:hover {
            background-color: #cfec59a3;

            p {
              color: $black_dark;
            }
          }

          p {
            color: #A2A2A2;
            font-family: $secundary_font;
            font-size: pxToRem(20px);
          }

          &.active {
            background: rgba(176, 224, 98, 0.575);
          }
        }
      }

      .input-create-container {
        align-items: center;
        border: 2px solid #CFEC59;
        display: flex;
        height: 50px;
        margin-top: 18px;
        width: 395px;

        input {
          border: none;
          color: $black_dark;
          font-family: $secundary_font;
          font-size: pxToRem(20px);
          font-weight: 700;
          height: 100%;
          margin: 0;
          outline: none;
          padding: 0 10px;
          width: 80%;
        }

        button {
          background-color: transparent;
          background-image: url("../../../../assets/images/add-journey-green.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          border: none;
          cursor: pointer;
          height: 100%;
          margin: 0;
          outline: none;
          padding: 0;
          width: 20%;
        }
      }
    }
  }
}
