@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/fonts.scss";
@import "../../../../assets/scss/function.scss";

.CollectionShare {
  .edit-collection-share {
    background-color: $white;
    box-shadow: 0 3px 6px #122F421A;
    display: flex;
    justify-content: space-between;
    padding: 104px 0 0 122px;
    position: fixed;
    width: 100%;
    z-index: 9;

    div {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-around;
      padding: 59px 244px 34px 0;

      h2 {
        font-size: pxToRem(32px);
        font-weight: 600;
        margin-bottom: 20px;
      }
    }
  }

  .collection-share-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    height: 100%;
    justify-content: flex-start;
    margin-left: 123px;
    min-height: 40vh;
    padding: 249px 0 100px 0;

    .ContentCard {
      margin-top: 73px;
    }
  }
}
