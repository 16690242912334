@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/function";

.NotesDescription {
  color: $black;
  height: fit-content;
  margin: 31px 0 30px 0;
  padding-left: 59px;
  width: 100%;

  .notes-container-comment {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .notes-text-comment {
      padding-left: 61px;

      .notes-select-comment {
        align-items: center;
        border-left: 2px solid #b5b5b5;
        display: flex;
        margin-bottom: 23px;
        min-height: 39px;
        text-transform: lowercase;
        width: 475px;

        p {
          color: #9e9e9e;
          font-size: pxToRem(20px);
          font-style: italic;
          padding-left: 18px;
          text-align: left;
        }
      }

      .notes-description-comment {
        height: 39px;
        padding-bottom: 22px;

        p {
          color: $black_dark;
          display: -webkit-box;
          font-size: pxToRem(20px);
          max-width: 683px;
          opacity: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
      }
    }

    .notes-delete-comment {
      background-color: unset;
      background-image: url("../../../../assets/images/red-delete-icon.svg");
      background-size: cover;
      border: none;
      cursor: pointer;
      height: 52px;
      margin: 22px 29px 0 67px;
      outline: none;
      width: 52px;
    }
  }
}
