.ExitButton {
  background-color: transparent;
  background-image: url("../../../assets/images/blue-exit-icon.svg");
  border: none;
  cursor: pointer;
  display: block;
  height: 70px;
  outline: none;
  width: 70px;
}
