@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/fonts.scss";
@import "../../../../assets/scss/function.scss";

.SearchTag {
  display: flex;
  flex-direction: column;
  margin-left: 7%;
  width: 60%;

  &.active {
    display: none;
  }
  
  p {
      color: $black;
      font-family: $secundary_font;
      font-size: pxToRem(25px);
      letter-spacing: -0.4px;
      text-align: center;
      width: 687px;
  }

  .search-input {
    border-bottom: 1.4px solid $black;
    display: flex;
    height: 32px;
    justify-content: space-between;
    margin: 72px 0 34.5px 0;
    padding-bottom: 19.5px;
    width: 100%;

    input {
      align-self: flex-end;
      background-color: transparent;
      border: none;
      color: $black;
      font-family: $secundary_font;
      font-size: pxToRem($search_tag);
      font-weight: 400;
      outline: 0;
      padding: 0;
      width: 67%;

      &::placeholder {
        color: $black;
        font-size: pxToRem($search_tag);
        opacity: 0.25;
      }
    }

    .ArrowButton {
      background-color: transparent;
      border: none;
      color: $black;
      cursor: pointer;
      display: flex;
      font-family: Grotesk, sans-serif;
      font-size: pxToRem($tag_size);
      font-weight: 400;
      justify-content: flex-end;
      letter-spacing: 0px;
      padding-bottom: 5px;
      width: 164px;

      &:focus {
        background-color: unset;
        outline: none;
      }
    }
  }

  .ArrowButton {
    align-items: center;
    background-color: $light_green;
    border-radius: 45px;
    border: none;
    color: $light_black;
    cursor: pointer;
    display: flex;
    font-family: Grotesk, sans-serif;
    font-size: pxToRem($search_tag);
    font-weight: 400;
    height: 42px;
    justify-content: center;
    width: 284px;
  }
}
