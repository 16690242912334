@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/function";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/fonts";

.RedefinePassword {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;

  h2 {
    display: none;
    font-size: pxToRem(31px);
    font-weight: 700;
    letter-spacing: -1.99px;
    padding-bottom: 35px;
    text-align: left;

    @include breakpoint(desktop-small){
      display: flex;
    }
  }

  h3 {
    font-size: pxToRem(22px);
    font-weight: 700;
    margin-right: 18px;
    width: 252px;

    @include breakpoint(desktop-small) {
      display: none;
    }
  }

  .Input {
    border-radius: 13px;
    margin: 32px 0 18px 0;
    width: 300px;

    p {
      height: 20px;
      margin: 0;

      @include breakpoint(desktop-small) {
        display: flex;
      }
    }

    input {
      border: none;
      background-color: $white_smoke;
      border-radius: 13px;
      height: 56px;
      width: 290px;
    }

    @include breakpoint(desktop-small) {
      border-radius: 0;
      height: 48px;
      margin: 0 0 35px 0;
      width: 399px;

      input {
        background-color: $white;
        border: 1px solid $erie_black;
        border-radius: 0;
        width: 387px;
      }
    }
  }

  p {
    color: $light_black;
    font-size: pxToRem(13px);
    font-weight: 400;
    height: 38px;
    letter-spacing: 0.1px;
    line-height: 23px;
    opacity: 60%;
    width: 262px;

    @include breakpoint(desktop-small) {
      display: none;
      opacity: 1;
    }
  }

  .Button {
    border-radius: 33px;
    color: $black;
    font-size: pxToRem(17px);
    font-weight: 500;
    height: 37px;
    margin-top: 170px;
    width: 182px;

    &:nth-child(1) {
      box-shadow: none;
      font-size: pxToRem(17px);
      font-weight: 500;
      height: 25px;
      letter-spacing: 0.02px;
      margin: 0;
      outline: none;
      padding: 0;
      width: 86px;
    }

    @include breakpoint(desktop-small) {
      border-radius: 0;
      color: $black;
      font-size: pxToRem(22px);
      font-weight: 700;
      height: 48px;
      margin: 0;
      width: 100%;

      &:nth-child(1) {
        font-size: pxToRem(20px);
        font-weight: 700;
      }
    }
  }

  .comeback-login {
    margin-top: 19px;
    text-align: center;
    width: 100%;
  }

  @include breakpoint(desktop-small) {
    align-items: flex-start;
  }

  .LoaderButton {
    border-radius: 33px;
    box-shadow: 0 3px 6px #122f421a;
    height: 37px;
    margin: 170px 0 0 10px;
    width: 182px;

    @include breakpoint(desktop-small) {
      align-items: center;
      background-color: #CFEC59;
      border-radius: 0;
      contain: size;
      display: flex;
      height: 48px;
      justify-content: center;
      margin: 0;
      outline: transparent;
      width: 399px;
    }
  }
}
