@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/fonts";

.Entry {
  display: flex;
  justify-content: center;
  width: 100%;

  .bg-entry {
    background: url("../../../assets/images/bg-login.png") no-repeat 100%;
    background-size: cover;
    display: none;
    width: 64%;

    @include breakpoint(desktop-small) {
      display: flex;
      height: 100vh;
    }
  }

  .entry-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .exit-entry-mobile {
      background: transparent;
      border: none;
      height: 30px;
      outline: none;
      position: absolute;
      right: 25px;
      top: 17px;
      width: 30px;
      z-index: 11;

      &:before, &:after {
        background-color: $black_dark;
        content: ' ';
        height: 20px;
        left: 15px;
        position: absolute;
        width: 1px;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }

      @include breakpoint(desktop-small) {
        display: none;
      }
    }

    .exit-entry {
      background-color: transparent;
      background-image: url("../../../assets/images/blue-exit-icon.svg");
      border: none;
      cursor: pointer;
      display: none;
      height: 70px;
      outline: none;
      position: absolute;
      right: 24px;
      top: 40px;
      width: 70px;
    }

    .title-mobile {
      font-size: pxToRem(34px);
      font-weight: 500;
      letter-spacing: -1.83px;
      margin-bottom: 30px;
      text-align: center;
    }

    .select-mobile {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-top: 83px;

      div {
        display: flex;
        height: 30px;
        justify-content: space-between;
        margin-bottom: 20px;
        width: 300px;

        button {
          padding: 2px 0 5px 0;
          background-color: white;
          border: none;
          border-bottom: 1px solid rgba(146, 146, 146, 0.67);
          color: $gray_register;
          font-family: Grotesk, sans-serif;
          font-size: pxToRem(15px);
          font-weight: 500;
          outline: none;
          width: 155px;

          &.login {
            color: #212121;
            font-weight: 600;
            border-bottom: 1px solid #212121;
          }

          &.registrar {
            color: #212121;
            font-weight: 600;
            border-bottom: 1px solid #212121;
          }
        }
      }
    }

    @include breakpoint(desktop-small) {
      flex-direction: initial;
      width: 36%;

      .title-mobile {
        display: none;
      }

      .exit-entry {
        display: flex;
      }

      .select-mobile {
        display: none;
      }
    }
  }

  @include breakpoint(desktop-small) {
    align-items: center;
    background-color: #ffffffa6;
    bottom: 0;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100vw;
    z-index: 5;
  }
}
