@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/function";

.ArrowButton {
  background-color: unset;
  border: none;
  color: $blue_dark_button;
  cursor: pointer;
  font-family: Grotesk, sans-serif;
  font-size: pxToRem($tag_size);
  font-weight: 400;
  padding: 0;

  &:focus {
    outline: none;
  }
}
