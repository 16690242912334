@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/fonts";

.HomeLanding {
  align-items: center;
  background-color: $blue_magenta;
  display: flex;
  flex-direction: column;

  .container-landing {
    align-items: center;
    background-color: $blue_magenta;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 60px;

    img {
      height: 331px;
      width: 100%;

      @include breakpoint(mobile-medium){
        width: auto;
      }
    }
  }

  .bottom-landing {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    p {
      color: $white;
      font-size: pxToRem(22px);
      font-weight: 700;
      margin-top: 18px;
      text-align: center;
      width: 276px;

      &:nth-of-type(2) {
        margin: 0 0 60px 0;
        width: 289px;
      }
    }
  }

  .arrowBox {
    padding-bottom: 30px;
  }
}
