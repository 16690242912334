@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/function";
@import "../../../../assets/scss/fonts";

.chat-modal {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 405px;
  height: 519px;
  background: white;
  z-index: 20;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px 10px 20px;
    height: 69px;
    box-sizing: border-box;
    background: #0505be;
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .chat-title {
      display: flex;
      align-items: center;
      gap: 10px;

      .info-text {
        display: flex;
        flex-direction: column;

        p {
          font-size: 24px;
          font-weight: 700;
          font-family: $secundary_font;
          color: white;
          margin: 0;
        }

        span {
          font-size: 16px;
          font-family: $secundary_font;
          font-weight: 500;
          color: white;
        }
      }

      .container-image {
        display: flex;
        align-items: center;
        background-color: white;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        justify-content: center;
        align-items: center;

        img {
          width: 70%;
        }
      }
    }

    button {
      background: transparent;
      border: none;
      outline: none;
      color: white;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .chat-content {
    flex: 1;
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .hour {
      width: 100%;
      text-align: center;
      font-size: 14px;
      font-family: $secundary_font;
      color: #717171;
      margin-bottom: 20px;
    }

    .references-container {
      display: flex;
      gap: 10px;

      .references-icon {
        align-items: center;
        background-color: #f0f0f0;
        border-radius: 50%;
        display: flex;
        font-family: $secundary_font;
        font-size: 15px;
        font-weight: 400;
        height: 29px;
        justify-content: center;
        min-height: 29px;
        min-width: 29px;
        position: relative;

        .tooltip {
          background-color: #555;
          border-radius: 6px;
          bottom: 125%;
          box-sizing: border-box;
          color: #fff;
          margin-right: -20px;
          opacity: 0;
          padding: 5px 0;
          position: absolute;
          left: 0%;
          text-align: center;
          transition: opacity 0.3s;
          visibility: hidden;
          width: 120px;
          z-index: 1;

          &::after {
            border-color: #555 transparent transparent transparent;
            border-style: solid;
            border-width: 5px;
            content: "";
            margin-left: -5px;
            position: absolute;
            left: 10%;
            top: 100%;
          }
        }

        &:hover .tooltip {
          visibility: visible;
          opacity: 1;
        }
      }

      .references {
        p {
          color: black;
          font-family: $secundary_font;
          font-size: 14px;
          font-weight: 700;
          margin: 0;
        }

        ul {
          list-style-type: none;
          list-style: none;
          padding-left: 0;

          li {
            border-bottom: 2px dotted black;
            color: #717171;
            font-family: $secundary_font;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 10px;
            width: fit-content;

            a {
              color: #120136;
              font-weight: 500;
              outline: none;
              text-decoration: none;

              &:hover {
                color: #717171;
                cursor: pointer;
              }
            }

            &:hover {
              border-bottom: 2px dotted #717171;
            }
          }
        }
      }
    }

    .chat-message {
      background: #f1f1f1;
      border-radius: 10px 10px 10px 0px;
      padding: 8px 12px;
      margin-bottom: 15px;
      max-width: 80%;
      font-size: 15px;
      font-family: $secundary_font;
      font-weight: 400;
      color: #717171;
      width: max-content;
      position: relative;

      .references-icon {
        align-items: center;
        background-color: #120136;
        border-radius: 50%;
        bottom: 0;
        display: flex;
        height: 29px;
        justify-content: center;
        position: absolute;
        position: absolute;
        right: -35px;
        width: 29px;

        .tooltip {
          background-color: #555;
          border-radius: 6px;
          bottom: 125%;
          box-sizing: border-box;
          color: #fff;
          margin-left: -80px;
          opacity: 0;
          padding: 5px 0;
          position: absolute;
          right: 0%;
          text-align: center;
          transition: opacity 0.3s;
          visibility: hidden;
          width: 120px;
          z-index: 1;

          &::after {
            border-color: #555 transparent transparent transparent;
            border-style: solid;
            border-width: 5px;
            content: "";
            margin-left: -5px;
            position: absolute;
            right: 8%;
            top: 100%;
          }
        }

        &:hover .tooltip {
          visibility: visible;
          opacity: 1;
        }
      }

      ul {
        margin: 5px;
        padding-left: 20px;
      }

      .chat-message--loading {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .loading-dots {
        display: flex;
        gap: 4px;

        span {
          display: block;
          width: 8px;
          height: 8px;
          background-color: #333;
          border-radius: 50%;
          animation: loading 1s infinite alternate;

          &:nth-child(2) {
            animation-delay: 0.2s;
          }
          &:nth-child(3) {
            animation-delay: 0.4s;
          }
        }
      }

      @keyframes loading {
        from {
          opacity: 0.2;
        }
        to {
          opacity: 1;
        }
      }
    }

    .chat-message--user {
      background: #120136;
      border-radius: 10px 10px 0px 10px;
      align-self: flex-end;
      max-width: 65%;
      width: max-content;
      color: white;
    }
  }

  .chat-input {
    display: flex;
    padding: 0 15px;
    box-sizing: border-box;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin: 0 20px 10px;
    border-radius: 24px;
    border: 1px solid #a8a8a8;

    input {
      flex: 1;
      padding: 10px;
      border: none;
      font-size: 14px;
      font-family: $secundary_font;
      border-radius: 5px;
      outline: none;
      margin-right: 10px;

      &:disabled {
        background-color: white;
      }
    }

    button {
      padding: 10px 20px;
      background-color: transparent;
      border: none;
      outline: none;
      border-radius: 5px;
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
