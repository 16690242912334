@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/fonts";
@import "../../../../assets/scss/function";

.Collection {
  height: 408px;
  width: 397px;

  .collection-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    display: flex;
    height: 223px;
    justify-content: flex-end;
    width: 397px;

    .length-collection {
      align-items: center;
      background-color: #00000080;
      border-radius: 0 16px 16px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 30%;

      p {
        color: $white;
        font-family: 'Roboto';
        font-size: pxToRem(24px);
      }

      img {
        margin: 10px 0 0 5px;
      }
    }
  }

  .title-collection {
    color: $black;
    font-family: SpaceGrotesk, sans-serif;
    font-size: pxToRem(25px);
    font-weight: 700;
    margin-top: 25px;
  }

  .profile-collection {
    align-items: center;
    display: flex;
    margin-top: 10px;

    img {
      border-radius: 100%;
      height: 35px;
      margin-right: 12px;
      width: 36px;
    }

    p {
      color: $black;
      font-family: $secundary_font;
      font-size: pxToRem(18px);
    }
  }

  button {
    align-items: center;
    background-color: transparent;
    border-radius: 26px;
    border: 2px solid $blue_magenta;
    color: $blue_magenta;
    cursor: pointer;
    display: flex;
    font-family: $secundary_font;
    font-size: pxToRem(17px);
    font-weight: 700;
    height: 44px;
    justify-content: space-around;
    margin-top: 40px;
    text-transform: uppercase;
    width: 250px;

    &:focus {outline:0;}
  }
}
