@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.EditUser {
  align-items: flex-start;
  display: flex;
  height: 80vh;
  justify-content: center;

  .exit-edit {
    display: none;
    position: relative;
    right: 152px;
    top: -344px;
    width: 170px;

    button {
      align-items: center;
      background: url("../../../assets/images/exit-arrow-profile.svg") 1% 52% no-repeat;
      border: none;
      cursor: pointer;
      display: flex;
      font-family: Roboto, sans-serif;
      font-size: pxToRem(16px);
      font-weight: 500;
      gap: 14px;
      height: 30px;
      justify-content: space-evenly;
      outline: none;
      width: 170px;
    }

    @include breakpoint(desktop-small) {
      display: flex;
    }
  }

  .container-edit {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    display: flex;
    margin-top: 90px;
    width: 100%;

    .select-edit {
      align-items: center;
      border-right: 1px solid $black_border_opacity;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      .buttons-edit {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;

        button {
          background-color: white;
          background-image: url("../../../assets/images/arrow-edit-profile.svg");
          background-position: right;
          background-repeat: no-repeat;
          border: none;
          border-bottom: 1px solid #7373737a;
          color: $black_dark;
          cursor: pointer;
          font-family: Grotesk, sans-serif;
          font-size: pxToRem(15px);
          font-weight: 400;
          height: 50px;
          outline: none;
          padding: 0;
          text-align: left;
          width: 85%;
        }

        @include breakpoint(desktop-small) {
          .mobile {
            display: none;
          }

          button {
            background: unset;
            border-bottom: 1px solid $black_border_opacity;
            font-size: pxToRem(18px);
            height: 75px;
            outline: none;
            padding-left: 41px;
            text-align: left;
            width: 273px;
          }
        }
      }

      .delete-profile {
        align-items: flex-start;
        border-top: 1px solid $black_border_opacity;
        display: none;
        flex-direction: column;
        height: 112px;
        justify-content: center;
        padding-left: 33px;
        width: 88%;

        p {
          font-size: pxToRem(22px);
          font-weight: 500;
          letter-spacing: 0.35px;
          margin-bottom: 5px;
          padding-left: 7px;
        }

        button {
          background-color: transparent;
          border: none;
          color: #0095F6;
          cursor: pointer;
          font-family: Grotesk, sans-serif;
          font-size: pxToRem(17px);
          font-weight: 400;
          outline: none;
        }

        @include breakpoint(desktop-small) {
          display: flex;
        }
      }

      @include breakpoint(desktop-small) {
        border-right: 1px solid $black_border_opacity;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 273px;
      }
    }

    .area-edit {
      align-items: flex-start;
      display: none;
      flex-direction: column;
      padding: 82px 0 0 147px;
      width: 100%;
    }

    @include breakpoint(desktop-small) {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 10px;
      box-shadow: 50px 50px 99px $black_border_opacity;
      display: flex;
      height: 717px;
      margin-left: 42px;
      margin-top: 184px;
      width: 1038px;

      .area-edit {
        display: flex;
      }
    }
  }

  @include breakpoint(desktok-small) {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    margin-bottom: 100px;
  }

  @include breakpoint(desktop-small) {
    height: 100vh;
  }
}
